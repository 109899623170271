import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SocketProvider, UserProvider } from "contexts";
import { CssBaseline } from "@mui/material";
import i18n from "i18n";
import { I18nextProvider } from "react-i18next";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GG_CLIENT_ID}`}>
        <SocketProvider>
          <CssBaseline />
          <UserProvider>
            <App />
          </UserProvider>
        </SocketProvider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  </QueryClientProvider>
);
