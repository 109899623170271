export const KEY_CONTEXT = {
	AUTH_TOKEN: "authToken",
	REFRESH_TOKEN: "refreshToken",
	LANGUAGE: "language",
	THEME_MODE: "themeMode",
	USER: "user",
	IMGS: "imgs",
	UID: "uid",
};

export const ACTIVE_TAB = {
	LIVE_CHAT: "live_chat",
	INTERPRETER: "interpreter",
	PLAY_GAME: "play_game",
};

export enum EnumValueDataType {
	String = "String",
	Number = "Number",
	Boolean = "Boolean",
	Object = "Object",
	Array = "Array",
	Null = "Null",
	Undefined = "Undefined",
	Function = "Function",
}

export const QueryKeys = {
	PROFILE: "profile",
	MODEL_EXAMPLES: "models_examples",
	CLOTHES_EXAMPLES: "clothes_examples",
	MODELS: "models",
	GALLERY_LIST: "galleryList",
	GET_CLOTHES: "getClothes",
	GET_PLAN: "getPlan",
	GET_DETAIL_PLAN: "c",
};


export const STEP_GAME = {
  INTRO: "intro",
  QUESTION: "question",
  SHOW_IMAGE: "show_image",
  ANSWERING: "answering",
  ANSWER_CORRECT: "answer_correct",
  ANSWER_INCORRECT: "answer_incorrect",
};


export const initialOptionsPaypal = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};

export const DEFAULT_LANG: any = {
	ko: "Korean",
	en: "English",
};

export const EXPECT_LANG = ["ko", "en"];

export const duration = 100;

export const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

export const wrapVideo: any = {
	objectFit: "contain",
	position: "relative",
};

export const transitionStyles: any = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 },
};
