/** @format */

import React, { memo } from 'react';
import { ISVGIconWrap } from 'types';

const SvgIconCus = ({
  children,
  width,
  height,
  size = 12,
  wrapColor = 'none',
  viewBox,
  ...props
}: ISVGIconWrap) => {
  return (
    <svg
      width={width || size}
      height={height || size}
      fill={wrapColor}
      {...props}
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
};

export default memo(SvgIconCus);
