import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { FormLoginData, FormLoginSocial, FormRegisterData } from "types";

export const authService = {
	refreshToken: async (rfToken: string) => {
		return await axiosInstance.post(END_POINT.AUTH.REFRESH_TOKEN, {
			refreshToken: rfToken,
		});
	},
	login: async (formData: FormLoginData) => {
		return await axiosInstance.post(END_POINT.AUTH.LOGIN, formData);
	},
	loginGoogle: async (idToken: FormLoginSocial) => {
		return await axiosInstance.post(END_POINT.AUTH.LOGIN_SOCIAL, idToken);
	},
	register: async (formData: FormRegisterData) => {
		return await axiosInstance.post(END_POINT.AUTH.REGISTER, {
			userName: formData.name,
			email: formData.email,
			password: formData.password,
		});
	},
	verifyOtp: async (otp: string) => {
		return await axiosInstance.post(END_POINT.AUTH.VERIFY_OTP, {
			otp,
		});
	},
	verifyEmail: async (otp: string) => {
		return await axiosInstance.post(END_POINT.AUTH.VERIFY_EMAIL, {
			otp,
		});
	},
	changePassword: async (formData: { uid: string; newPassword: string }) => {
		return await axiosInstance.post(END_POINT.AUTH.CHANGE_PASSWORD, {
			uid: formData.uid,
			newPassword: formData.newPassword,
		});
	},
};
