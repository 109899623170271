import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const useDevice = () => {
	const platForm = "web-app";
	const [ipAddress, setIpAddress] = useState<string>("");
	const [deviceId, setDeviceId] = useState<string>("");

	useEffect(() => {
		let deviceId = localStorage.getItem("deviceId");
		if (!deviceId) {
			deviceId = uuidv4();
			localStorage.setItem("deviceId", deviceId);
		}
		setDeviceId(deviceId);
	}, []);

	return { ipAddress, platForm, deviceId };
};

export default useDevice;
