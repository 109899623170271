import React from "react";
import { Box, Typography } from "@mui/material";
import { Text } from "components/Text";
import { fontSize } from "theme/default";
import { IMessage } from "types";
import dayjs from "dayjs";

interface IUserMessage {
	item: IMessage;
}

const UserMessage = ({ item }: IUserMessage) => {
	return (
		<Box
			display={"flex"}
			justifyContent={"end"}
			gap={"10px"}
			alignItems={"end"}>
			<Box
				display={"flex"}
				alignItems={"end"}
				gap={"7px"}
				maxWidth={"90%"}
				flexDirection={"column"}>
				<Box
					width={"fit-content"}
					borderRadius={"24px 24px 0px 24px"}
					bgcolor={"rgba(115, 64, 216, 0.2)"}
					padding={"10px"}>
					<Text>{item?.message}</Text>
				</Box>
				<Typography fontSize={fontSize.s12} fontWeight={"400"}>
					{dayjs(item.createdAt).format("hh:mm:ss")}
				</Typography>
			</Box>
		</Box>
	);
};

export default UserMessage;
