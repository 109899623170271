import { IColorDefault, IFontSize } from "types/theme";
export const mainColor: IColorDefault = {
    primary: '#FFFFFF',
    secondary: '#7340D8',
    warning: '#FFCC00',
    success: '#3CD4A0',
    info: '#9013FE',
    error: '#750606',
    grey: '#F5F5F5',
    black: '#000000',
    textMain: '#3A3A3C',
    textGray: '#858688',
    borderColor:"#ADADAD",
}

export const fontSize: IFontSize = {
    s12: "12px",
    s14: "14px",
    s16: "16px",
    s18: "18px",
    s20: "20px",
    s25: "25px"
}