import React from "react";
import { Box, Divider, Grid, IconButton, Popover } from "@mui/material";
import { Text } from "components/Text";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fontSize, mainColor } from "theme/default";
import { HeaderProps } from "types";
import { Link } from "react-router-dom";
import AvatarCus from "components/Avatar";
import ItemMenu from "./ItemMenu";
import { logo } from "assets/images";
import { Icons } from "assets";

export const Header: React.FC<HeaderProps> = () => {
	// =========== OPEN MODAL INFO =============
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null,
	);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<Box
			display={"flex"}
			justifyContent={"center"}
			component={"nav"}
			height={"76px"}
			borderBottom={`1px solid ${mainColor.borderColor}`}
			bgcolor={mainColor.grey}>
			<Grid container sx={{ height: "100%" }} width={"100%"} paddingX={"40px"}>
				<Grid
					item
					xs={6}
					display={"flex"}
					alignItems={"center"}
					justifyContent={"start"}>
					<Box>
						<Link to={"/"}>
							<LazyLoadImage src={logo} alt="logo" effect="blur" />
						</Link>
					</Box>
				</Grid>
				<Grid
					item
					xs={6}
					display={"flex"}
					gap={"20px"}
					alignItems={"center"}
					justifyContent={"flex-end"}>
					<IconButton>{Icons.Notification()}</IconButton>
					<Box
						component={"button"}
						display={"flex"}
						alignItems={"center"}
						bgcolor={"transparent"}
						border={"none"}
						sx={{ cursor: "pointer", gap: { xs: "10px", md: "20px" } }}
						onClick={handleClick}
						aria-describedby={id}>
						<AvatarCus />
					</Box>

					{/* ========== POPUP MENU ============ */}
					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						sx={{
							top: "10px",
							overflow: "hidden",
							borderRadius: "20px",
						}}>
						<Box
							width={"242px"}
							paddingX={"15px"}
							paddingY={"20px"}
							display={"flex"}
							overflow={"hidden"}
							bgcolor={mainColor.primary}
							flexDirection={"column"}
							gap={"15px"}
							borderRadius={"20px !important"}>
							<Box alignItems={"center"} gap={"10px"} display={"flex"}>
								<AvatarCus />
								<Box display={"flex"} flexDirection={"column"}>
									<Text
										fontSize={fontSize.s16}
										color={mainColor.textMain}
										fontWeight={"600"}>
										Name
									</Text>
									<Text
										fontSize={fontSize.s12}
										color={mainColor.textMain}
										fontWeight={"400"}>
										johnpham@gmail.com
									</Text>
								</Box>
							</Box>
							<Divider />
							<ItemMenu
								icon={Icons.Home()}
								title={"Edit profile"}
								onAction={() => {}}
							/>
							<ItemMenu
								isMgTop
								icon={Icons.Setting()}
								title={"Setting"}
								onAction={() => {}}
							/>
							<Divider />
							<ItemMenu
								icon={Icons.Help()}
								title={"Help center"}
								onAction={() => {}}
							/>
							<ItemMenu
								isMgTop
								icon={Icons.Logout()}
								title={"Logout"}
								onAction={() => {}}
							/>
						</Box>
					</Popover>
				</Grid>
			</Grid>
		</Box>
	);
};
