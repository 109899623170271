import React from "react";
import { Box } from "@mui/material";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";

interface IItemMenu {
	isMgTop?: boolean;
	title: string;
	icon: React.ReactNode;
	onAction: () => void;
}

const ItemMenu = ({ onAction, icon, title, isMgTop = false }: IItemMenu) => {
	return (
		<Box
			marginTop={isMgTop ? "10px" : "0px"}
			component={"div"}
			onClick={onAction}
			sx={{ cursor: "pointer" }}
			borderRadius={"10px"}
			display={"flex"}
			paddingLeft={"10px"}
			alignItems={"center"}
			gap={"20px"}>
			{icon}
			<Text fontSize={fontSize.s16} color={mainColor.textMain}>
				{title}
			</Text>
		</Box>
	);
};

export default ItemMenu;
