import React, { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "contexts";
import { privateRoutes, publicRoutes, RouterType } from "routes";
import LoginPage from "pages/LoginPage";
import { ThemeProvider } from "@mui/material/styles";
import theme from "theme/theme";
import { ToastContainer } from "react-toastify";
import i18next from "i18next";
import useUserStore from "stores/userStore";
import { MainLayout, SubLayout } from "layouts";
import useDevice from "hooks/useDevice";
import useDetectMultiTab from "hooks/useDetectMultiTab";
import { ModalGlobal } from "components/ModalGlobal";
import useLoginOneDevice from "hooks/useLoginOneDevice";
import { useCommon, useKey } from "hooks";
import { KEY_CONTEXT } from "utils";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  if (!isAuthenticated) {
    navigate("/login");
  }
  return children;
};

const App: React.FC = () => {
  const { setKey, getKey, removeKey } = useKey();
  const { deviceId } = useDevice();
  const { languages } = useUserStore();
  const { genUUID } = useCommon();

  useDetectMultiTab();
  useLoginOneDevice(deviceId);

  useEffect(() => {
    if (languages.value) {
      i18next.changeLanguage(languages.value);
    }
  }, [languages.value]);

  useEffect(() => {
    const uid = getKey(KEY_CONTEXT.UID);
    if (!uid) {
      setKey(KEY_CONTEXT.UID, genUUID());
    }

    removeKey(KEY_CONTEXT.IMGS);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <ModalGlobal />
      <AuthProvider>
        <Router>
          <Routes>
            {publicRoutes.map((route: RouterType, index) => {
              const Layout = route.layout || SubLayout;
              const Component = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <Layout>
                      <Component />
                    </Layout>
                  }
                />
              );
            })}
            {privateRoutes.map((route: RouterType, index) => {
              const Layout = route.layout || MainLayout;
              const Component = route.component;
              return (
                <Route
                  key={`${index}-${route.path}`}
                  path={route.path}
                  element={
                    <RequireAuth>
                      <Layout>
                        <Component />
                      </Layout>
                    </RequireAuth>
                  }
                />
              );
            })}
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
