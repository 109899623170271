import { ButtonProps, TypographyProps } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { TColorDefault } from "./theme";
import { ReactNode, SVGProps } from "react";
import { EnumValueDataType } from "utils";
import { IModelType } from "./outfit";

export type ISXTheme = SxProps<Theme>;

export enum EnumBtnBottomModal {
	cancel = "cancel",
	close = "close",
}

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		small: true;
		smallSemiBold: true;
		smallBold: true;
		bodyBold: true;
		bodySemiBold: true;
	}
}

export type ITextInit = TypographyProps;
export interface IText extends ITextInit {
	children?: string;
	disabled?: boolean;
	colorTheme?: TColorDefault;
	color?: string;
	useI18n?: boolean;
}

export type ISVGIconWrap = SVGProps<SVGSVGElement> & {
	children?: JSX.Element | JSX.Element[];
	size?: number;
	wrapColor?: string;
	width?: number;
	height?: number;
};

export type ISVGIcon = SVGProps<SVGPathElement> &
	ISVGIconWrap & {
		color?: string;
	};

export type IButtonVariantStyle =
	| "cancel"
	| "error"
	| "info"
	| "success"
	| "warning"
	| "default"
	| "none";

export type IButtonIconType =
	| ""
	| "cancel"
	| "close"
	| keyof typeof EnumBtnBottomModal;

export type IButtonIcon = ISVGIcon & {
	variant?: IButtonIconType;
};

export type IValueType = keyof typeof EnumValueDataType;

export type IButton = ButtonProps & {
	variantStyle?: IButtonVariantStyle;
	title?: string;
	useI18n?: boolean;
	variantTitle?: IText["variant"];
	LabelProps?: IText;
	iconProps?: IButtonIcon | null;
	isLoading?: boolean;
	isActive?: boolean;
};

export interface IBreadCrumbs {
	path: string;
	onClick?: (url?: string) => void;
}

export interface IItemTabPanel {
	label: string;
	content: ReactNode | void | JSX.Element | JSX.Element[];
}

export type TParams = {
	page: number;
	limit: number;
	typeModel?: "MALE" | "FEMALE";
	typeOutfit?: "UPPER" | "LOWER" | "DRESSES";
	[x: string]: string | number | undefined;
};

export type TPagination = {
	limit: number;
	page: number;
	total: number;
	totalPage: number;
};

export interface IPaginationProps {
	total: number;
	onChange?: (p: number) => void;
}

export interface ImageListProps {
	images?: IModelType[];
	selectedImage?: string;
	setSelectedImage?: (image: IModelType) => void;
	isLoading?: boolean;
	dropType: string;
}

export type TResponse<T> = {
	data: T;
	pagination?: TPagination;
	statusCode: number;
	message: string;
};

export enum EDropType {
	BODY = "BODY",
	OUTFIT = "OUTFIT",
}

export enum STATUS_PROCESSING {
	START_PROCESS = "START_PROCESS",
	PROCESSING = "PROCESSING",
	FINAL = "FINAL",
}

export enum TypeStatusDevice {
	ONLINE = "ONLINE",
	OFFLINE = "OFFLINE",
	REGISTER = "REGISTER",
}

export enum TypeSendMailOtp {
	REGISTER = "REGISTER",
	FOR_GOT_PASS_WORD = "FOR_GOT_PASS_WORD",
}

export enum EPricingPlan {
	ANNUAL = "Annual",
	MONTHLY = "Monthly",
}

export enum STATUS_PAYMENT {
	ANNUAL = "ANNUAL",
	SUCCESS = "SUCCESS",
	FAIL = "FAIL",
}

export enum ELanguage {
	VI = "vi",
	EN = "en",
}
