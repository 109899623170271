import {
	dispatchActions,
	useUserDispatch,
	useUserState,
} from "contexts/UserContext";
import { IUserActionsType } from "types";
import { useCallback, useState } from "react";
import { ACTIVE_TAB, ENDPOINT_ASSISTANT, KEY_CONTEXT } from "utils";
import { ModelType } from "types";
import axiosInstance from "services/axios";
import { v4 as uuidv4 } from 'uuid'
import { useKey } from "./useKey";

export function useCommon() {
	const {
		selectedType = ModelType.IMAGE,
		language = "en",
		avatar = null,
		isVideoPlaying = false,
		eventName = "init",
		showOptions = false,
		activeTab = ACTIVE_TAB.LIVE_CHAT,
		default_url = null,
		streamId = null,
		sessionId = null,
		isShowModalExpire = false
	} = useUserState() || {};

	
	const {setKey, getKey} = useKey()

	const getItem = (imgs: any) => {
		try {
			const val = localStorage.getItem(imgs);
			if (val) {
				return JSON.parse(val);
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	const localAvatar = localStorage.getItem(KEY_CONTEXT.IMGS) || "";
	const [loading, setLoading] = useState(false);
	const userDispatch = useUserDispatch();

	const onUpdateParams = useCallback(
		(payload: any) => {
			dispatchActions(
				{ type: IUserActionsType.UPDATE_PARAMS, payload },
				userDispatch,
			);
		},
		[userDispatch],
	);

	const onUploadAvatar = async (file: File, cb?: () => void) => {
		try {
			let uid = getKey(KEY_CONTEXT.UID);
			if (!uid) {
				uid = uuidv4();
				setKey(KEY_CONTEXT.UID, uid);
				
			}

			setLoading(true);
			const formData = new FormData();
			formData.append("file", file);
			formData.append("uid", uid);
			formData.append("background_color", "#EBEBEBEB");
			const rs: any = await axiosInstance.post(`${ENDPOINT_ASSISTANT.UPLOAD}`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			});
			setLoading(false);
			cb?.();
			if (rs.status === 200 && rs?.["default_url"]) {
				setKey(KEY_CONTEXT.IMGS, rs?.["default_url"]);
				onUpdateParams({ default_url: rs?.["default_url"] });
			}
			return rs;
		} catch (error) {
			console.log("Error ============>", error);
			setLoading(false);
			cb?.();
		}
	};

	const onStopStream = () => {
		// streamComponentRef?.current?.onStopStream?.();
		// streamComponentRef?.current?.stopWithOutCloseConnection?.();
	};

	const genUUID = () => uuidv4();

	return {
		activeTab,
		showOptions,
		isVideoPlaying,
		/** DID */
		streamId,
		sessionId,
		/**User Context */
		onUpdateParams,
		avatar,
		selectedType,
		onStopStream,
		onUploadAvatar,
		localAvatar: localAvatar ? JSON.parse(`${localAvatar}`) : {},
		default_url,
		loading,
		language,
		eventName,
		getItem,
		isShowModalExpire,
		genUUID
	};
}
