/** @format */

import React, { FC, memo } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ISXTheme, IText } from "types";
import { areEqual } from "utils";
import { mainColor } from "theme/default";

const Text: FC<IText> = ({
	useI18n = true,
	children,
	colorTheme,
	disabled = false,
	...props
}) => {
	const { t } = useTranslation();

	if (!children) return null;
	return (
		<Typography
			component={"span"}
			sx={
				[
					props?.onClick ? { cursor: "pointer" } : {},
					props?.sx,
					colorTheme && { color: mainColor[colorTheme] },
					{
						color: disabled ? "rgba(0, 0, 0, 0.55)" : "",
					},
				] as ISXTheme
			}
			{...props}>
			{useI18n ? t(children) : children}
		</Typography>
	);
};

export default memo(Text, areEqual);
