import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { imgDefault } from "assets/images";
import { fontSize, mainColor } from "theme/default";
import { IMessage } from "types";
import dayjs from "dayjs";
import TypingEffect from "./TypingEffect";
import { useKey } from "hooks";

interface IAiMessage {
  avt?: string;
  item: IMessage;
  stop?: boolean;
  onTypingComplete?: () => void;
}

const AiMessage = ({ item, stop, onTypingComplete }: IAiMessage) => {
  const { getKey } = useKey();
  const avtVideo = getKey("imgs");
  return (
    <Box display={"flex"} gap={"10px"} alignItems={"start"} mb={1}>
      {!item?.idx ? (
        <Avatar
          sx={{ width: "30px", height: "30px" }}
          src={avtVideo?.replace(".mp4", ".png") ?? imgDefault}
          alt="avt"
        />
      ) : (
        <Box width={30} height={30} />
      )}
      <Box
        display={"flex"}
        alignItems={"start"}
        maxWidth={"90%"}
        gap={"7px"}
        flexDirection={"column"}
      >
        <Box
          width={"fit-content"}
          borderRadius={"0 24px 24px 24px"}
          bgcolor={mainColor.grey}
          padding={"10px"}
        >
          <TypingEffect
            speed={40}
            stopStream={stop}
            text={item.message}
            onTypingComplete={onTypingComplete}
          />
        </Box>

        {item?.idx && item?.isTime && (
          <Typography fontSize={fontSize.s12} fontWeight={"400"}>
            {dayjs(item.createdAt).format("hh:mm:ss")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AiMessage;
