import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Modal } from "@mui/material";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";
import { ButtonCus } from "components/Button";
import { Icons } from "assets";
import { useCommon } from "hooks/useCommon";

interface IModalProfile {
  open: boolean;
  onClose: () => void;
}

const ModalUpload = ({ open, onClose }: IModalProfile) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | undefined>();
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const { loading, onUploadAvatar } = useCommon();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (previewUrl) {
      setIsPreviewVisible(false);
      timer = setTimeout(() => {
        setIsPreviewVisible(true);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [previewUrl]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  };

  const handleUpload = useCallback(() => {
    file &&
      onUploadAvatar(file, () => {
        onClose();
      });
  }, [file]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Modal
      keepMounted
      open={open}
      sx={{
        backgroundColor: "#000000087",
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        padding={"30px"}
        width={"640px"}
        height={"764px"}
        borderRadius={"24px"}
        bgcolor={mainColor.primary}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".png, .jpg, .jpeg, .webp"
          onChange={handleFileChange}
        />
        <Text
          color={mainColor.secondary}
          fontWeight={"700"}
          fontSize={fontSize.s18}
        >
          CREATE YOUR CHARACTER
        </Text>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={"20px"}
          flexDirection={"column"}
        >
          <Box
            width={"400px"}
            height={"400px"}
            position={"relative"}
            borderRadius={"24px"}
            sx={{
              background:
                "linear-gradient(to bottom, #7340D8 30%, #48D8E4 90%)",
            }}
          >
            <Box
              width={"96%"}
              height={"96%"}
              position={"absolute"}
              top={"50%"}
              left={"50%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"24px"}
              sx={{ transform: "translate(-50%,-50%)" }}
              bgcolor={mainColor.grey}
            >
              <img
                src={previewUrl ?? ""}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  opacity: isPreviewVisible ? 1 : 0,
                  transition: "opacity 0.5s ease-in-out",
                  borderRadius: "10px",
                }}
              />
            </Box>
          </Box>
          <Box
            onClick={() => handleClick()}
            component={"div"}
            bgcolor={"rgba(115, 64, 216, 0.15)"}
            width={"204px"}
            height={"48px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              cursor: "pointer",
              transition: open ? "all 0.25s ease-in-out" : "unset",
              ":hover": {
                backgroundColor: "#7340d885",
              },
            }}
            gap={"10px"}
            borderRadius={"32px"}
          >
            {Icons.Upload()}
            <Text
              fontSize={fontSize.s16}
              fontWeight={"600"}
              color={mainColor.secondary}
            >
              Upload character
            </Text>
          </Box>
        </Box>

        <ButtonCus
          isLoading={loading}
          onClick={handleUpload}
          sx={{ width: "224px" }}
        >
          CONFIRM
        </ButtonCus>
      </Box>
    </Modal>
  );
};

export default ModalUpload;
