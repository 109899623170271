import React, { useState } from "react";
import { Box } from "@mui/material";
import { Icons } from "assets";
import { ButtonCus, Text } from "components";
import { ModalUpload } from "components/ModalGlobal";
import { fontSize, mainColor } from "theme/default";
import { usePermission, useWindowDimensions } from "hooks";

const PageUpload = () => {
	const [openModalUpload, setOpenModalUpload] = useState(false);
	const { height } = useWindowDimensions();
	const { isBlocked } = usePermission();

	return (
		<Box
			position={"absolute"}
			width={"100%"}
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			height={height - 80}>
			<Box
				width={"400px"}
				height={"200px"}
				position={"relative"}
				borderRadius={"24px"}
				sx={{
					background: "linear-gradient(to bottom, #7340D8 30%, #48D8E4 90%)",
				}}>
				<Box
					width={"97%"}
					height={"95%"}
					position={"absolute"}
					top={"50%"}
					left={"50%"}
					borderRadius={"20px"}
					sx={{ transform: "translate(-50%,-50%)" }}
					bgcolor={mainColor.grey}
					display={"flex"}
					alignItems={"center"}
					flexDirection={"column"}
					justifyContent={"center"}
					gap={"20px"}>
					{isBlocked ? (
						<Text fontSize={fontSize.s16} fontWeight={"700"}>
							{`Your device's not support voice!`}
						</Text>
					) : (
						<>
							<Text fontSize={fontSize.s16} fontWeight={"700"}>
								Create Character To Start
							</Text>
							<ButtonCus
								onClick={() => setOpenModalUpload(true)}
								sx={{
									display: "flex",
									alignItems: "center",
									gap: "10px",
									height: "56px",
								}}>
								{Icons.PlusWhite()} Create Character
							</ButtonCus>
						</>
					)}
				</Box>
			</Box>
			{/* ============= MODAL CREATE CHARACTER ============== */}
			<ModalUpload
				open={openModalUpload}
				onClose={() => setOpenModalUpload(false)}
			/>
		</Box>
	);
};
400;

export default PageUpload;
