import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Grid } from "@mui/material";
import { Text } from "components";
import { TextAreaSearch } from "components/TextAreaSearch";
import { fontSize, mainColor } from "theme/default";
import MicListening from "components/MicListening/MicListening";
import AiMessage from "components/Messages/AiMessage";
import UserMessage from "components/Messages/UserMessage";
import PageUpload from "./components/PageUpload";
import { useCommon } from "hooks/useCommon";
import { useLiveChat } from "hooks/useLiveChat";
import { useOpenAI } from "hooks/useOpenAI";
import { useDID } from "hooks/useDID";
import {
  ACTIVE_TAB,
  KEY_CONTEXT,
  defaultStyle,
  duration,
  sleep,
  transitionStyles,
  wrapVideo,
} from "utils";
import { usePlayGame } from "hooks/usePlayGame";
import { EnumChatType, IMessage, ModelType } from "types";
import { Transition } from "react-transition-group";
import { LoadingChat, LoadingPage } from "components/Loading";
import { useKey, useWindowDimensions } from "hooks";
import { PlayGames } from "./components/PlayGames";
import ModalExpireSession from "components/ModalGlobal/ModalExpireSession";

const ChatPage = () => {
  const { removeKey, getKey } = useKey();
  const { height } = useWindowDimensions();
  const [message, setMessage] = useState("");
  const [loadingChat, setLoadingChat] = useState(false);
  const prevRef = useRef<any>({
    timeout: null,
    interval: null,
    timeoutPlay: null,
    isPlay: false,
    localAvatar: null,
    default_url: null,
    stream_id: null,
    session_id: null,
  }).current;

  const {
    selectedType,
    default_url,
    onUpdateParams,
    activeTab,
    showOptions,
    isVideoPlaying,
    isShowModalExpire,
  } = useCommon();
  const {
    updateMsg,
    messages,
    reseChat,
    onValidPlayGame,
    onChatBot,
    sayHi,
  } = useLiveChat();

  const remoteStreamRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { initDidStream, onStopStream, cleanup, setVideoDefault } = useDID(
    remoteStreamRef.current
  );
  const { loadingGame, countPlayGame, resetGame } = usePlayGame();

  // Handle recognite
  const {
    onStartRecognite,
    onStopRecognite,
    transcript,
    setTranscript,
  } = useOpenAI();

  const nodeRef = useRef(null);
  // const [stopStream, setStopStream] = useState(false);
  const [inTransit, setInTransit] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isOpenKB, setIsOpenKB] = useState(false);
  const [urlVideo, setUrlVideo] = useState(default_url);

  console.log("Loadinngn ==========>", isOpen, isLoading);

  const isLiveChatTab = useMemo(() => activeTab === ACTIVE_TAB.LIVE_CHAT, [
    activeTab,
  ]);

  // Hanlde stop stream when isLiveChatTab change
  useEffect(() => {
    if (activeTab == ACTIVE_TAB.LIVE_CHAT) {
      resetGame();
    }
    onStopStream();
  }, [activeTab]);

  useEffect(() => {
    if (!isOpen) {
      console.log("🚀 ~ ChatPage ~ showOptions:", showOptions);
      reseChat();
      resetGame();
      // removeKey(KEY_CONTEXT.IMGS);
      onUpdateParams({
        messages: [],
        messagesGame: [],
        default_url: null,
        activeTab: ACTIVE_TAB.LIVE_CHAT,
        isShowModalExpire: false,
      });
      cleanup();
      remoteStreamRef.current.srcObject = null;
      prevRef.default_url = null;
      setIsOpen(true);
    }
  }, [showOptions]);

  // Handle update message in store
  const onUpdateStoreMsg = async (msg: any, t: any) => {
    // setStopStream(false);
    // onStopStream();
    // await sleep(300);

    updateMsg(msg, t);
  };

  const sendMsg = useCallback(
    async (msg: string) => {
      // if (isVideoPlaying) return;
      const rs: any = await onValidPlayGame(msg);
      if (rs?.is_play) {
        onUpdateParams({
          activeTab: ACTIVE_TAB.PLAY_GAME,
          language: rs?.lang_id ?? "en",
        });
        if (prevRef.timeout) clearTimeout(prevRef.timeout);
        return false;
      }
      setLoadingChat(true);
      const result: any = await onChatBot(
        false,
        prevRef.session_id,
        prevRef.stream_id,
        msg
      );
      if (!isLiveChatTab) {
        return;
      }
      setLoadingChat(false);
      console.log("Result of onChatBot", result);

      if (
        result?.status === 400 &&
        "DID_SESSION_ERROR".includes(result?.["error"])
      ) {
        // Show modal alert expire session
        onUpdateParams({ isShowModalExpire: true });
        return;
        // const rsult = await initDidStream(
        //   remoteStreamRef.current,
        //   selectedType,
        //   prevRef.default_url
        // );
        // await sleep(1000);
        // if (rsult?.session_id) {
        //   prevRef.stream_id = rsult.id;
        //   prevRef.session_id = rsult.session_id;
        //   const result1 = await sendMsg(msg);

        //   if (!result1) return;
        //   result1?.text &&
        //     onUpdateStoreMsg(result1.text, EnumChatType.AI_ANSWER);

        //   return false;
        // }
      }

      return result;
    },
    [selectedType, isLiveChatTab, isVideoPlaying]
  );

  const initStream = useCallback(
    async (type: string, file: string) => {
      setIsLoading(true);
      try {
        const rs: any = await initDidStream(
          remoteStreamRef.current,
          type,
          file
        );

        console.log("Result of initDidStream", rs);
        if (rs?.session_id) {
          prevRef.stream_id = rs.id;
          prevRef.session_id = rs.session_id;
          setIsLoading(false);
          console.log(
            "isOpen, prevRef ===>",
            isOpen,
            prevRef,
            remoteStreamRef.current
          );

          if (isOpen) {
            setInTransit(false);
            setIsOpen(false);
            setVideoDefault(remoteStreamRef.current, file);
            setInTransit(true);
            setLoadingChat(true);

            const result: any = await sayHi(rs.session_id, rs.id);
            setUrlVideo(file);
            result?.text &&
              onUpdateStoreMsg(result.text, EnumChatType.AI_ANSWER);
            setLoadingChat(false);
            return true;
          }
        }
        if (typeof rs === "undefined") {
          cleanup();
          await sleep(100);
          initStream(type, file);
        }
      } catch (error) {
        console.log("Error ====>", error);
      } finally {
        setIsLoading(false);
      }
    },
    [remoteStreamRef?.current, isLiveChatTab, loadingGame]
  );

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);
      await sleep(2000);
      const imgs = getKey(KEY_CONTEXT.IMGS) ?? default_url;

      if (imgs && prevRef.default_url !== imgs && isOpen) {
        prevRef.default_url = imgs;
        onUpdateParams({
          selectedType: ModelType.IMAGE,
          activeTab: ACTIVE_TAB.LIVE_CHAT,
        });
        const rs = await initStream(ModelType.IMAGE, prevRef.default_url);
        if (rs) {
          !isLiveChatTab &&
            !loadingGame &&
            onUpdateParams({
              countPlayGame: countPlayGame + 1,
            });
        }
      } else {
        setIsLoading(false);
      }
    };
    remoteStreamRef.current && init();
  }, [default_url, remoteStreamRef.current]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ isVideoPlaying:", isVideoPlaying);

    if (!isVideoPlaying && remoteStreamRef.current) {
      setInTransit(false);
      setVideoDefault(remoteStreamRef.current, null);
      setInTransit(true);
    }
  }, [isVideoPlaying, remoteStreamRef.current]);

  useEffect(() => {
    const deplyScroll = async () => {
      await sleep(500);
      if (scrollRef.current) {
        scrollRef.current.scrollTo({
          top: scrollRef.current.scrollHeight + 20,
          behavior: "smooth",
        });
      }
    };
    if (isVideoPlaying) {
      prevRef.interval && clearInterval(prevRef.interval);
      prevRef.interval = setInterval(() => deplyScroll(), 500);
    } else {
      prevRef.interval && clearInterval(prevRef.interval);
    }
  }, [isVideoPlaying]);

  const onStop = () => {
    isPlay && onStopRecognite();
    setIsPlay(false);
  };

  const onStart = () => {
    !isPlay && onStartRecognite();
    setIsPlay(true);
  };

  useEffect(() => {
    if (isOpen || !isLiveChatTab) return;
    if (transcript) {
      if (prevRef.timeout) clearTimeout(prevRef.timeout);
      prevRef.timeout = setTimeout(async () => {
        onStop();
        onUpdateStoreMsg(transcript, EnumChatType.USER);
        setTranscript("");
        const result = await sendMsg(transcript);
        if (!result) return;
        result?.text && onUpdateStoreMsg(result.text, EnumChatType.AI_ANSWER);
      }, 2000);
    }
  }, [transcript, isOpen, isLiveChatTab]);

  const onSubmit = async () => {
    console.log("🚀 ~ onSubmit ~ e:", isLiveChatTab);
    if (message && isLiveChatTab) {
      onUpdateStoreMsg(message, EnumChatType.USER);
      const result = await sendMsg(message);
      if (!result) return;
      result?.text && onUpdateStoreMsg(result.text, EnumChatType.AI_ANSWER);
    }
  };

  return (
    <Box height={"calc(100vh - 76px)"}>
      <Grid container height={"100%"}>
        <Grid
          item
          xs={8}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"flex-end"}
        >
          <Box
            position={"relative"}
            height={"100%"}
            width={"50%"}
            overflow={"hidden"}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"center"}
          >
            {/* ========== DEFAULT VIDEO ==========*/}
            <Box
              position={"absolute"}
              top={0}
              width={"100%"}
              overflow={"hidden"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Transition in={inTransit} timeout={duration}>
                {(state) => (
                  <div
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <video
                      id="ai3rd"
                      src={urlVideo}
                      style={{
                        ...wrapVideo,
                        height: height - 80,
                        display:
                          selectedType === ModelType.IMAGE && !isOpen
                            ? "inline-block"
                            : "none",
                      }}
                      muted={false}
                      autoPlay={true}
                      playsInline
                      loop={true}
                    />
                  </div>
                )}
              </Transition>
            </Box>
            {/* ========= VIDEO STREAM ===========*/}
            <Box
              position={"absolute"}
              zIndex={1}
              width={"100%"}
              overflow={"hidden"}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Transition nodeRef={nodeRef} in={inTransit} timeout={duration}>
                {(state) => (
                  <div
                    ref={nodeRef}
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <video
                      id="ai3rd"
                      ref={remoteStreamRef}
                      style={{
                        ...wrapVideo,
                        height: height - 80,
                        display:
                          selectedType === ModelType.IMAGE && !isOpen
                            ? "inline-block"
                            : "none",
                      }}
                      muted={false}
                      autoPlay
                      playsInline
                      loop
                    />
                  </div>
                )}
              </Transition>
            </Box>
          </Box>
        </Grid>

        {!isOpen ? (
          <Grid item xs={4} padding={"30px"}>
            <Box
              height={"100%"}
              borderRadius={"24px"}
              bgcolor={mainColor.primary}
            >
              <Box
                height={"56px"}
                borderBottom={`1px solid ${mainColor.borderColor}`}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Text fontSize={fontSize.s16} fontWeight={"600"}>
                  Chat Box
                </Text>
              </Box>
              <Box
                padding={"12px"}
                display={`${isLiveChatTab ? "flex" : "none"}`}
                flexDirection={"column"}
                justifyContent={"space-between"}
                flex={1}
                height={"calc(100vh - 200px)"}
              >
                <Box
                  sx={{ overflowY: "auto" }}
                  display={"flex"}
                  padding={"10px"}
                  ref={scrollRef}
                  paddingBottom={"100px"}
                  flexDirection={"column"}
                >
                  {messages.map((item: IMessage, index: number) => {
                    if (item.type === EnumChatType.USER) {
                      return <UserMessage item={item} key={index} />;
                    } else {
                      return (
                        <AiMessage avt={default_url} item={item} key={index} />
                      );
                    }
                  })}
                </Box>
                <Box
                  position={"relative"}
                  border={`1px solid ${mainColor.secondary}`}
                  height={"auto"}
                  borderRadius={"24px"}
                >
                  <TextAreaSearch
                    setMessage={setMessage}
                    message={message}
                    handleSubmit={onSubmit}
                    onKeyDown={() => {}}
                    isPlayMic={isPlay}
                    onActionMic={() => {
                      isPlay ? onStop() : onStart();
                      setIsOpenKB(false);
                      onStopStream();
                    }}
                  />
                  {loadingChat ? (
                    <Box position={"absolute"} top={"50%"} left={"30px"}>
                      <LoadingChat />
                    </Box>
                  ) : null}
                  {isPlay ? (
                    <Box
                      position={"absolute"}
                      left={"50%"}
                      sx={{ transform: "translate(-50%)" }}
                      top={"-100px"}
                    >
                      <MicListening isListening={isPlay} />
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <Box
                display={`${!isLiveChatTab ? "flex" : "none"}`}
                padding={"12px"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                flex={1}
                height={"calc(100vh - 200px)"}
              >
                <PlayGames remoteStreamRef={remoteStreamRef.current} />
              </Box>
            </Box>
          </Grid>
        ) : isLoading ? (
          <LoadingPage />
        ) : (
          <PageUpload />
        )}
      </Grid>

      {isShowModalExpire && <ModalExpireSession />}
    </Box>
  );
};

export default ChatPage;
