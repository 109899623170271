/** @format */

import { useKey } from "hooks";
import theme from "theme/theme";
import { IThemeColor } from "types";
import { KEY_CONTEXT } from "utils";

export default () => {
	const { getKey } = useKey();
	const mode = getKey(KEY_CONTEXT.THEME_MODE) as IThemeColor;
	return {
		base: {
			textTransform: "unset",
			minWidth: 120,
			borderRadius: 3,
			height: 48,
		},
		contained: {
			default: {
				backgroundColor: theme.palette.primary[mode],
				border: `1px solid ${theme.palette.primary[mode]}`,
				color: "white",
				"&:hover": {
					color: theme.palette.primary[mode],
				},
			},
			error: {
				backgroundColor: theme.palette.error["light"],
				border: `1px solid ${theme.palette.error["light"]}`,
				color: "white",
				"&:hover": {
					color: theme.palette.error["light"],
				},
			},
			warning: {
				backgroundColor: theme.palette.warning["light"],
				border: `1px solid ${theme.palette.warning["light"]}`,
				color: "white",
				"&:hover": {
					color: theme.palette.warning["light"],
				},
			},
			success: {
				backgroundColor: theme.palette.success["light"],
				border: `1px solid ${theme.palette.success["light"]}`,
				color: "black",
				"&:hover": {
					color: theme.palette.success["dark"],
				},
			},
			info: {
				backgroundColor: theme.palette.info["light"],
				border: `1px solid ${theme.palette.info["light"]}`,
				color: "white",
				"&:hover": {
					color: theme.palette.info["light"],
				},
			},
			cancel: {
				backgroundColor: theme.palette.secondary["light"],
				color: "white",
				border: `1px solid ${theme.palette.secondary["light"]}`,
				"&:hover": {
					color: theme.palette.secondary["light"],
				},
			},
			none: {},
		},
		outlined: {
			none: {},
			default: {
				color: theme.palette.primary[mode],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.primary[mode],
				},
			},
			error: {
				color: theme.palette.error["light"],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.error["light"],
				},
			},
			warning: {
				color: theme.palette.warning["light"],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.warning["light"],
				},
			},
			success: {
				color: theme.palette.success["light"],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.success["light"],
				},
			},
			info: {
				color: theme.palette.info["light"],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.info["light"],
				},
			},
			cancel: {
				color: theme.palette.secondary["light"],
				border: `1px solid ${theme.palette.primary[mode]}`,

				"&:hover": {
					color: theme.palette.secondary["light"],
				},
			},
		},
		text: {
			none: {},
			default: {
				color: theme.palette.primary[mode],
				"&:hover": {
					color: theme.palette.primary[mode],
				},
			},
			error: {
				color: theme.palette.error["light"],
				"&:hover": {
					color: theme.palette.error["light"],
				},
			},
			warning: {
				color: theme.palette.warning["light"],
				"&:hover": {
					color: theme.palette.warning["light"],
				},
			},
			success: {
				color: theme.palette.success["light"],
				"&:hover": {
					color: theme.palette.success["light"],
				},
			},
			info: {
				color: theme.palette.info["light"],
				"&:hover": {
					color: theme.palette.info["light"],
				},
			},
			cancel: {
				color: theme.palette.secondary["light"],
				"&:hover": {
					color: theme.palette.secondary["light"],
				},
			},
		},
	};
};
