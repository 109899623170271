import * as yup from "yup";

const loginSchema = yup.object().shape({
	email: yup
		.string()
		.email("Please enter the valid email format.")
		.required("Email is required"),
	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
});

const registerSchema = yup.object().shape({
	name: yup.string().required("Name is required"),
	email: yup
		.string()
		.email("Invalid email format")
		.required("Email is required"),
	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), ""], "Passwords must match")
		.required("Confirm Password is required"),
	acceptPrivacy: yup
		.boolean()
		.oneOf([true], "You must accept the privacy policy")
		.required("You must accept the privacy policy"),
	subscribeEmail: yup.boolean(),
});

const dynamicSchema = (isOtpStep: boolean) =>
	yup.object().shape({
		email: isOtpStep
			? yup.string().email("Invalid email")
			: yup.string().email("Invalid email").required("Email is required"),
		otp: isOtpStep
			? yup
					.string()
					.length(6, "OTP must be exactly 6 digits")
					.required("OTP is required")
			: yup.string().notRequired(),
	});

const resetPassSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(8, "New password must be at least 8 characters")
		.required("New password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), ""], "Passwords must match")
		.required("Confirm Password is required"),
});

export { loginSchema, registerSchema, dynamicSchema, resetPassSchema };
