import {create} from "zustand";
import { devtools } from "zustand/middleware";


type State = {
	openModalGlobal: boolean;
	loadingGlobal: boolean;

}

type Actions = {
	setOpenModalGlobal: (v: boolean) => void;
	setLoadingGlobal: (v: boolean) => void;
}


const initialState: State = {
	openModalGlobal: false,
	loadingGlobal: false,
}

const useLayoutStore = create<State & Actions>()(
	devtools(
		(set) => ({
			...initialState,
			setOpenModalGlobal: (v) => set({ openModalGlobal: v }),
			setLoadingGlobal: (v) => set({ loadingGlobal: v }),
		}),
		{
			name: "LayoutStore",
		},
	),
);

export default useLayoutStore;
