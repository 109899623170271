import React from "react";
import { Avatar } from "@mui/material";
import { avtDf } from "assets/images";
import useUserStore from "stores/userStore";

const AvatarCus = () => {
	const { user } = useUserStore();
	return (
		<Avatar
			src={user?.avatar?.length ? user?.avatar : avtDf}
			sx={{
				display: { xs: "none", md: "flex" },
				width: "40px",
				height: "40px",
			}}
		/>
	);
};

export default AvatarCus;
