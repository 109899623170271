import { createTheme } from '@mui/material';

const overrides = {
  typography: {
    fontFamily: '"Quicksand", sans-serif',
    h1: {
      fontSize: '48px',
    },
    h2: {
      fontSize: '32px',
    },
    h3: {
      fontSize: '26px',
    },
    h4: {
      fontSize: '24px',
    },
    h5: {
      fontSize: '20px',
    },
    h6: {
      fontSize: '18px',
    },
  },
};

const theme = createTheme({ ...overrides });

export default theme;