import React, { useState } from "react";
// import { IMAGES } from "assets";
import TypingEffect from "components/Messages/TypingEffect";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { imgDefault } from "assets/images";
import { fontSize, mainColor } from "theme/default";
import { useCommon } from "hooks";
import { KEY_CONTEXT } from "utils";

interface IIntroductionProps {
  intro: string;
  onTypingComplete?: () => void;
  item?: any;
}

export const Introduction: React.FC<IIntroductionProps> = ({
  intro,
  onTypingComplete,
  item,
}) => {
  const [stop, setStop] = useState(false);
  const { getItem } = useCommon();
  const avtVideo = getItem(KEY_CONTEXT.IMGS);
  return (
    <Box display={"flex"} gap={"10px"} alignItems={"start"} mb={1}>
      {!item?.idx ? (
        <Avatar
          sx={{ width: "30px", height: "30px" }}
          src={avtVideo?.replace(".mp4", ".png") ?? imgDefault}
          alt="avt"
        />
      ) : (
        <Box width={30} height={30} />
      )}
      <Box
        display={"flex"}
        alignItems={"start"}
        maxWidth={"90%"}
        gap={"7px"}
        flexDirection={"column"}
      >
        <Box
          width={"fit-content"}
          borderRadius={"0 24px 24px 24px"}
          bgcolor={mainColor.grey}
          padding={"10px"}
        >
          <TypingEffect
            speed={50}
            text={intro}
            stopStream={stop}
            onTypingComplete={(a: any) => {
              onTypingComplete?.();
              setStop(a);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
