export enum EnumChatType {
  AI = "AI",
  USER = "USER",
  AI_ANSWER = "AI_ANSWER",
  USER_ASK = "USER_ASK",
}

export interface btnItem {
  id: number;
  active: string;
  title: string;
  image: any;
}

export interface IMessage {
  createdAt: Date;
  type: EnumChatType;
  message: string;
  idx?: boolean,
  isTime?: boolean,
}

export enum EnumUploadType {
  NONE = "none",
  FILE = "file_txt",
  MP3 = "file_mp3",
  VOICE_TO_TEXT = "voice_to_text",
}
export interface IPlayGame {
  type: EnumPlayGameType;
  introduction?: IIntroductionItem;
  listimages?: IImagesItem;
  question?: IQuestionItem;
  answer?: IAnswerItem;
  stopStream?: boolean
}

interface IImagesItem {
  image_urls?: string[];
  images?: string[];
}

interface IIntroductionItem {
  text: string;
  image_urls?: string[];
  images?: string[];
}

interface IQuestionItem {
  title?: string;
  question?: string | any;
}

interface IAnswerItem {
  title?: string;
  image?: string | any;
  type: EnumAnswerType;
}

export enum EnumPlayGameType {
  LISTIMAGE = "listimage",
  INTRODUCTION = "introduction",
  QUESTION = "question",
  ANSWER = "answer",
}

export enum EnumAnswerType {
  INCORRECT = "incorrect",
  CORRECT = "correct",
}

export enum EnumLanguage {
  EN = "en",
  KO = "ko",
}

export enum AudioSource {
  URL = "URL",
  FILE = "FILE",
  RECORDING = "RECORDING",
}

export enum ModelType {
  VIDEO= "video",
  IMAGE = "image",
}

export enum EVirtualModelType {
  NATURAL= "natural",
  UN_NATURAL = "un-natural",
}
