import { useEffect, useState } from "react";

export function usePermission() {
  const [isBlocked, setIsBlocked] = useState(true);

  useEffect(() => {
    getLocalStream();
  }, []);

  const getLocalStream = async (cb?: (t: any) => void) => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        await navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function (stream) {
            cb?.(stream)
            // stream.getTracks().forEach(track => {
            //   track.stop()
            // })
            console.log("Permission granted");
            setIsBlocked(false);
          })
          .catch(function (err) {
            console.log("🚀 ~ getLocalStream ~ err:", err)
            setIsBlocked(true);
          });
      }
    } catch (error) {
      console.log("🚀 ~ getLocalStream ~ error:", error);
      setIsBlocked(true);
    }
  };

  return { getLocalStream, isBlocked };
}
