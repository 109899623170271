import { Mic, Stop } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { mainColor } from "theme/default";

interface PropsTextAreaSearch {
  setMessage: Dispatch<SetStateAction<string>>;
  message?: string;
  handleSubmit: () => Promise<void>;
  loading?: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isFinished?: boolean;
  placeholder?: string;
  uploadFile?: React.ReactNode;
  onActionMic: () => void;
  isPlayMic?: boolean;
}

const TextAreaSearch: React.FC<PropsTextAreaSearch> = ({
  setMessage,
  message,
  handleSubmit,
  loading = false,
  onKeyDown,
  placeholder,
  onActionMic,
  isPlayMic,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [stateTextArea, setStateTextArea] = useState({
    rows: 1,
    minRows: 1,
    maxRows: 5,
  });

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage && setMessage(e.target.value);
    const textareaLineHeight = 24;
    const { minRows, maxRows } = stateTextArea;

    const previousRows = e.target.rows;
    e.target.rows = minRows;

    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows;
      e.target.scrollTop = e.target.scrollHeight;
    }

    setStateTextArea({
      ...stateTextArea,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };

  const onclickSubmit = async () => {
    if (!message?.length) return;
    setStateTextArea({ ...stateTextArea, rows: 1 });
    await setMessage("");
    await handleSubmit();
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    onKeyDown(e);
    if (e.key === "Enter") {
      onclickSubmit();
    }
    // else if (+e.code === 13 && e.shiftKey) {
    // 	setStateTextArea({ ...stateTextArea, rows: 1 });
    // }
  };

  return (
    <Box
      display={"flex"}
      padding={"10px"}
      width={"100%"}
      height={"100%"}
      gap={"10px"}
      alignItems={"center"}
      borderRadius={"12px 24px"}
    >
      <Box
        component={"textarea"}
        width={"94%"}
        height={"100%"}
        sx={{
          outline: "none",
          bgcolor: "transparent",
          resize: "none",
          padding: "10px",
          border: "none",
        }}
        disabled={loading}
        value={message}
        id="textAreaSearch"
        onKeyDown={(e) => handleKeydown(e)}
        rows={stateTextArea.rows}
        ref={textAreaRef}
        onChange={(e) => handleChange(e)}
        placeholder={loading ? "Loading..." : placeholder}
      ></Box>
      <Box display={"flex"} alignItems={"end"} gap={"10px"} height={"100%"}>
        <IconButton onClick={onclickSubmit} disabled={!message?.length}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M2.70314 18.6505L6.13097 12.7536C6.40189 12.2876 6.40188 11.712 6.13094 11.2459L2.70316 5.34958C2.57007 5.12063 2.49335 4.85979 2.51529 4.59588C2.57396 3.88994 2.81437 3.3868 3.46102 3.26745C3.66892 3.22908 3.8803 3.28495 4.07322 3.3714L20.7655 10.8508C20.9844 10.9489 21.1694 11.1082 21.2062 11.3452C21.2644 11.7197 21.2643 12.2876 21.2057 12.66C21.1689 12.8937 20.986 13.0505 20.7701 13.1472L4.073 20.6287C3.88022 20.7151 3.66901 20.7709 3.46125 20.7327C2.81441 20.6136 2.57396 20.1102 2.51528 19.4042C2.49335 19.1403 2.57006 18.8794 2.70314 18.6505Z"
              stroke={message?.length ? mainColor.secondary : "#858688"}
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M21 12L6.87281 11.9998"
              stroke={message?.length ? mainColor.secondary : "#858688"}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </IconButton>
        <IconButton
          sx={{
            background: isPlayMic
              ? "linear-gradient(to bottom, #d84040 30%, #e47448 90%)"
              : "linear-gradient(to bottom, #7340D8 30%, #48D8E4 90%)",
          }}
          onClick={onActionMic}
        >
          {isPlayMic ? <Stop /> : <Mic />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default TextAreaSearch;
