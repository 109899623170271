import { useCallback, useRef, useState } from "react";
import { useCommon } from "./useCommon";
import { ENDPOINT_ASSISTANT, KEY_CONTEXT, sleep } from "utils";
import axiosInstance from "services/axios";
import { useKey } from "./useKey";

type TRef = {
  stream_id: string, 
  session_id: string, 
  type: string, 
  videoFile: string,
  intervalId: any,
  timeoutId?: any,
  isDefault?: any,
  lastBytesReceived?: any, 
  videoIsPlaying3rd?: any,
  mediaStream?: MediaStream | null,
}

export function useDID(remoteStreamRef: any) {
  const { onUpdateParams } = useCommon();
  const { getKey } = useKey()
  const stream = useRef<TRef>({ stream_id: "", session_id: "", type: "", videoFile: "", intervalId: null, mediaStream: null}).current;
  const peerClient = useRef<RTCPeerConnection | null>();
  const [streamDid, setStreamDid] = useState<any>()
  
  const initDidStream = async (remoteStream:any, type: any, file: any) => {
    console.log("🚀 ~ initDidStream ~ remoteStream:", remoteStream)
    if (
      peerClient.current &&
      peerClient.current.connectionState === "connected"
    ) {
      return;
    }
    const uid = getKey(KEY_CONTEXT.UID)
    await postRequest(`${ENDPOINT_ASSISTANT.CREATE_CHATBOT}`, { uid });
    remoteStreamRef = remoteStream
    const sessionResponse: any = await postRequest(ENDPOINT_ASSISTANT.CREATE_NEW_STREAM, { type, uid });
    console.log("🚀 ~ initDidStream ~ sessionResponse:", sessionResponse)
    const {
      id: newStreamId,
      offer,
      ice_servers: iceServers,
      session_id: newSessionId,
    } = sessionResponse;
    stream.session_id = newSessionId;
    stream.stream_id = newStreamId;
    stream.type = type;
    stream.videoFile = file;
    onUpdateParams({ streamId: newStreamId, sessionId: newSessionId });

    const sessionClientAnswer = await createPeerConnection(
      offer,
      iceServers,
    );
    // this.isIceCandidate = false;
    const rs = await postRequest(`${ENDPOINT_ASSISTANT.START_STREAM}/${newStreamId}`,
    {
      stream_id: newStreamId,
      answer: sessionClientAnswer,
      session_id: newSessionId,
      type,
    }
    );
    await sleep(3000);
    console.log("🚀 ~ initDidStream ~ rs:", rs)
    return sessionResponse;
  }

  const createPeerConnection = async (
    offer: RTCSessionDescriptionInit,
    iceServers: RTCIceServer[],
  ) => {
    peerClient.current = new RTCPeerConnection({ iceServers });
    peerClient.current.addEventListener("icecandidate", onIceCandidate);
    peerClient.current.addEventListener("track", onTrack);

    await peerClient.current.setRemoteDescription(offer);

    const sessionClientAnswer = await peerClient.current.createAnswer();

    await peerClient.current.setLocalDescription(sessionClientAnswer);

    return sessionClientAnswer;
  }

  const onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
    if (event.candidate) {
      // this.isIceCandidate = true
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;
      postRequest(`${ENDPOINT_ASSISTANT.SUBMIT_NETWORK}/${stream.stream_id}`, {
        stream_id: stream.stream_id,
        candidate,
        sdp_mid: sdpMid,
        sdp_mline_index: sdpMLineIndex,
        session_id: stream.session_id,
        type: stream.type,
      });
    }
  }

  const onTrack = useCallback(async (event: RTCTrackEvent) => {
    const event_stream = event.streams[0]
    if (event.track.kind === "video") {
      stream.mediaStream = event_stream;
      if (stream.intervalId) clearInterval(stream.intervalId)
    // console.log("🚀 ~ StreamComponent ~ onTrack ~ stream:", stream, typeof stream)
    setStreamDid(stream.mediaStream)
      // remoteStreamRef.srcObject = event.streams[0];
      // console.log("🚀 ~ onTrack ~ event.streams[0]:", event.streams[0])

      stream.intervalId = setInterval(async () => {
        try {
          const stats = await peerClient.current?.getStats(event.track);
            stats?.forEach((report) => {
                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    const videoStatusChanged = stream.videoIsPlaying3rd !== (report.bytesReceived > stream.lastBytesReceived);
                    // console.log("🚀 ~ stats?.forEach ~ videoStatusChanged:", videoStatusChanged, remoteStreamRef)
                    if (videoStatusChanged && remoteStreamRef) {
                      stream.videoIsPlaying3rd = report.bytesReceived > stream.lastBytesReceived;
                        if (!stream.videoIsPlaying3rd) {
                          console.log("🚀 ~ StreamComponent ~ onTrack ~ stream: stop", remoteStreamRef, stream.videoFile);
                          onUpdateParams({ isVideoPlaying: false });
                          setVideoDefault(null, null)
                        }
                        if (stream.videoIsPlaying3rd) {
                          console.log("🚀 ~ StreamComponent ~ onTrack ~ stream: start");
                          remoteStreamRef.muted = false;
                          remoteStreamRef.playsInline = true;
                          remoteStreamRef.src = "";
                          remoteStreamRef.srcObject = event_stream;
                          onUpdateParams({ isVideoPlaying: true });
                        }
                    }
                    stream.lastBytesReceived = report.bytesReceived;
                }
            });
        } catch (error) {
          
        }
        }, 500);
    }
  }, [remoteStreamRef])

  const onStopStream = useCallback(() => {
    if (remoteStreamRef && stream.mediaStream) {
      // remoteStreamRef.srcObject = null;
      // stream.mediaStream?.getTracks().forEach(track => track.stop());
      stream.mediaStream.getTracks()[0].enabled = false
      remoteStreamRef.srcObject = null;
      onUpdateParams({ isVideoPlaying: false });

      setTimeout(() => {
        if (stream.mediaStream) {
          stream.mediaStream.getTracks()[0].enabled = true
        }
        
      }, 1000)
    }
  }, [remoteStreamRef])

  const postRequest = async (url: string, params: any) => {
    const data = await axiosInstance.post(url, params);
    return data
  }

  const setVideoDefault = useCallback((remoteStream: any, videoFile?: string|null) => {
    const url = videoFile ?? stream.videoFile;
    if (remoteStream) remoteStreamRef = remoteStream;
    if (remoteStreamRef && !stream.isDefault) {
    console.log("🚀 ~ StreamComponent ~ onTrack ~ stream: setVideoDefault ~ remoteStreamRef:", remoteStreamRef, stream.videoFile)
    stream.isDefault = true
      remoteStreamRef.src = "";
      remoteStreamRef.srcObject = null;
      remoteStreamRef.src = url;
      remoteStreamRef.autoPlay = true;
      remoteStreamRef.loop = true;
      remoteStreamRef.playsInline = true;
      remoteStreamRef.load();
      setTimeout(() => stream.isDefault = false, 1000)
    }
  }, [remoteStreamRef])

  const cleanup = () => {
    if (peerClient.current) {
      peerClient.current?.close();
      // peerClient.current = null;
    }
    if (remoteStreamRef) {
      remoteStreamRef.srcObject = null;
      remoteStreamRef.src = "";
    }
  }

  return {
    initDidStream,
    onStopStream,
    cleanup,
    setVideoDefault,
    streamDid
  }
}
