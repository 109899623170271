/* eslint no-misleading-character-class: 0 */  
/* eslint no-control-regex: 0 */  

export function sleep(n: number) {
  return new Promise((resolve) => setTimeout(resolve, n));
}

export const generateTimeTitle = () => {
  const fewSecondsAgo = new Date(new Date().getTime() - 10 * 1000);
  const oneMinuteAgo = new Date(new Date().getTime() - 60 * 1000);
  // const oneHourAgo = new Date(new Date().getTime() - 60 * 60 * 1000);

  const now = new Date();
  if (now.getTime() - fewSecondsAgo.getTime() < 60 * 1000) {
    return "a few seconds ago";
  } else if (now.getTime() - oneMinuteAgo.getTime() < 60 * 60 * 1000) {
    return "a minute ago";
  } else {
    return "a hours ago";
  }
};

export const base64Image = (baseImg: string) => `data:image/png;base64, ${baseImg}`;

export const createNewFileTest = () => {
  const fileContent = "Hello Word!";
  const blob = new Blob([fileContent ], { type: 'text/plain' });
  return new File([blob], "text.txt")
}

export const arrayBufferToBase64 = (buffer: any) => {
  let binary: any = "";
  const bytes: any = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return `${window.btoa(binary)}`;
};

const detect = (text: any) => {
  const scores: any = {}
  
  const regexes = {
    'en': /[\u0000-\u007F]/gi,
    'zh': /[\u3000\u3400-\u4DBF\u4E00-\u9FFF]/gi,
    'hi': /[\u0900-\u097F]/gi,
    'ar': /[\u0621-\u064A\u0660-\u0669]/gi,
    'bn': /[\u0995-\u09B9\u09CE\u09DC-\u09DF\u0985-\u0994\u09BE-\u09CC\u09D7\u09BC]/gi,
    'he': /[\u0590-\u05FF]/gi,
    'ko': /[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/gi,
  }
  for (const [lang, regex] of Object.entries(regexes)) {
    const matches = text.match(regex) || []
    const score = matches.length / text.length
    if (score) {
      if (score > 0.85) {
        return lang
      }
      scores[lang] = score
    }
  }
  // not detected
  if (Object.keys(scores).length == 0)
    return null
  // pick lang with highest percentage
  return Object.keys(scores).reduce((a, b) => scores[a] > scores[b] ? a : b);
}

export const detectLanguage = (text: any) => {
  try {
    // split into words
    const langs = text.trim().split(/\s+/).map((word: any) => {
      return detect(word)
    })
    // pick the lang with the most occurances
    return (langs || []).reduce( ( acc: any, el: any ) => {
      acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1
      acc.max = acc.max ? acc.max < acc.k[el] ? el : acc.max : el
      return acc  
    }, { k:{} }).max as string
  } catch (error) {
    return null
  }
  
}