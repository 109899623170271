/**
 * @format
 * @description define English language resource
 */

export default {
  txt: {},
  lable: {
    title_login: 'Login',
  },
  loading_text: 'Loading...',
};
