import { toast } from "react-toastify";

export function useAlert() {
	const success = (msg: string) => {
		return toast.success(msg, {
			position: "top-center",
			toastId: JSON.stringify(msg),
			className: "toast-message",
		});
	};
	const error = (msg: unknown) => {
		return toast.error(`${msg}`, {
			position: "top-center",
			toastId: JSON.stringify(msg),
			className: "toast-message",
		});
	};

	const info = (msg: string) => {
		return toast.info(msg, {
			position: "top-center",
			toastId: JSON.stringify(msg),
			className: "toast-message",
		});
	};

	const warn = (msg: string) => {
		return toast.warn(msg, {
			position: "top-center",
			toastId: JSON.stringify(msg),
			className: "toast-message",
		});
	};

	return { success, error, info, warn };
}
