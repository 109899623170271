import { Box } from "@mui/material";
import React from "react";
import { fontSize, mainColor } from "theme/default";
import { Text } from "components";
import { useWindowDimensions } from "hooks";

const LoadingPage = ({ isExpire = false }: any) => {
  const { height } = useWindowDimensions();
  return (
    <Box
      position={"absolute"}
      width={"100%"}
      height={height - 80}
      display={"flex"}
      flexDirection={"column"}
      gap={"60px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <div className="loader_page">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      {isExpire && (
        <Text
          color={mainColor.secondary}
          fontWeight={"700"}
          fontSize={fontSize.s18}
        >
          Session Expire
        </Text>
      )}
      <Text color={mainColor.secondary} fontWeight={"500"} fontStyle={"italic"}>
        Please wait, AI is initializing...
      </Text>
    </Box>
  );
};

export default LoadingPage;
