import { useUserState } from "contexts/UserContext";
import { useCallback, useRef, useState } from "react";
import { IPlayGame } from "types";
import { useCommon } from "./useCommon";
import axiosInstance from "services/axios";
import { useKey } from "./useKey";
import { KEY_CONTEXT } from "utils";

export const usePlayGame = () => {
	// const {initDidStream} = useDID(remoteStreamRef)
	const { getKey } = useKey()
	const uid = getKey(KEY_CONTEXT.UID)
	const { onUpdateParams, selectedType, sessionId, streamId } =
		useCommon();
	const {
		draftMsgGame = null,
		messagesGame = [],
		countPlayGame = 0,
	} = useUserState() || {};

	const msgRef = useRef<{ messages: IPlayGame[] }>({ messages: [] }).current;
	const [loadingGame, setLoadingGame] = useState(false);

	const updateGameMsg = useCallback(
		(msg: IPlayGame) => {
			// if (activeTab !== ACTIVE_TAB.PLAY_GAME) return
			msgRef.messages.push(msg);
			console.log("🚀 ~ updateGameMsg ~ msgRef.messages:", msgRef.messages);
			onUpdateParams({ messagesGame: msgRef.messages });
		},
		[msgRef.messages, messagesGame],
	);

	const resetGame = () => {
		msgRef.messages = [];
		onUpdateParams({ messagesGame: [] });
	};

	const onGetImagesQuestion = useCallback(
		async (
			language?: string,
			is_first?: boolean,
			background_color = "#EBEBEB",
		) => {
			try {
				const result: any = await postRequest(`/game/questions`, {
					language: language ?? "english",
					background_color: background_color,
					is_first: is_first,
					uid,
					is_did_requested: true,
					stream_id: streamId ?? "testID", // this.streamId ??
					session_id: sessionId ?? "testID", // this.sessionId ??
					type: selectedType ?? "testID",
				});
				return result;
			} catch (error) {
				return null;
			}
		},
		[streamId, sessionId, selectedType],
	);

	const onGetListQuestion = useCallback(
		async (
			image_urls: any,
			language?: string,
			background_color = "#EBEBEB",
		) => {
			
			try {
				const result: any = await postRequest(`/game/question`, {
					image_urls: image_urls,
					background_color: background_color,
					language: language ?? "english",
					is_did_requested: true,
					uid,
					stream_id: streamId ?? "testID", // this.streamId ??
					session_id: sessionId ?? "testID", // this.sessionId ??
					type: selectedType ?? "testID",
				});
				return result;
			} catch (error) {
				return null;
			}
		},
		[streamId, sessionId, selectedType],
	);

	const onAnswer = useCallback(
		async (
			language?: string,
			description?: any,
			answer?: string,
			is_correct = false,
			background_color = "#EBEBEB",
		) => {
			try {
				const result: any = await postRequest(`/game/answer`, {
					answer: answer,
					description: description,
					background_color,
					is_correct,
					uid,
					is_did_requested: true,
					language: language ?? "english",
					stream_id: streamId ?? "testID", // this.streamId ??
					session_id: sessionId ?? "testID", // this.sessionId ??
					type: selectedType ?? "testID",
				});
				return result;
			} catch (error) {
				return null;
			}
		},
		[streamId, sessionId, selectedType],
	);

	const postRequest = async (url: string, params: any) => {
		const data = await axiosInstance.post(url, params);
		console.log("🚀 ~ postRequest ~ data:", data);

		return data;
	};

	return {
		draftMsgGame,
		messagesGame,
		loadingGame,
		countPlayGame,
		setLoadingGame,
		updateGameMsg,
		resetGame,
		onGetImagesQuestion,
		onGetListQuestion,
		onAnswer,
	};
};
