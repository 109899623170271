import React from 'react'
import { Box } from '@mui/material'
import { Icons } from 'assets'


interface IMicListening {
    isListening: boolean
}


const MicListening = ({isListening}: IMicListening) => {
  return (
        <Box>
            <Box onClick={() => {}} className={`mic ${isListening ? "listening" : ''}`} component={"button"} type="button" >
                {Icons.Mic()}
            </Box>
        </Box>
    )
}

export default MicListening
