import React from "react";
import { Avatar, Box } from "@mui/material";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";

import { base64Image } from "utils";
import { LoadingChat } from "components/Loading";

interface QuestionMessage {
  time?: string;
  questions?: any;
  onAction: (i: any) => void;
  question?: string;
  loadingSubmit: {
    loading: boolean;
    idxItem: number;
  };
  keyIndex: number;
}

const QuestionMessage = ({
  time,
  questions,
  onAction,
  question,
  loadingSubmit,
  keyIndex,
}: QuestionMessage) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"start"}
      paddingLeft={"10px"}
      gap={"10px"}
      borderRadius={"24px"}
      paddingY={"10px"}
      width={"fit-content"}
      alignItems={"start"}
    >
      <Box
        display={"flex"}
        alignItems={"start"}
        gap={"7px"}
        maxWidth={"100%"}
        width={"fit-content"}
        flexDirection={"column"}
      >
        <Text fontSize={fontSize.s16} fontWeight={"400"} textAlign={"start"}>
          {question}
        </Text>
        <Box
          padding={"20px"}
          sx={{
            padding: {
              xs: "10px",
              xl: "20px",
            },
            gap: {
              xs: "10px",
              xl: "20px",
            },
          }}
          borderRadius={"10px"}
          width={"fit-content"}
          display={"flex"}
          position={"relative"}
          bgcolor={mainColor.grey}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {questions.map((item: any, index: any) => (
            <Box
              component={"div"}
              borderRadius={"10px"}
              overflow={"hidden"}
              key={index}
              onClick={() => onAction(index)}
            >
              <img
                alt=""
                src={base64Image(item)}
                style={{
                  width: "100%",
                  display: "block",
                  borderRadius: "15px",
                  padding: 3,
                  cursor: "pointer",
                }}
              />
            </Box>
          ))}
          {loadingSubmit.loading && keyIndex === loadingSubmit.idxItem && (
            <Box
              display={"flex"}
              borderRadius={"10px"}
              alignItems={"center"}
              bgcolor={"#cccccc80"}
              justifyContent={"center"}
              width={"100%"}
              height={"100%"}
              position={"absolute"}
              top={0}
              left={0}
            >
              <LoadingChat />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionMessage;
