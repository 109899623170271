import React from "react";
import { Box, Button, Modal } from "@mui/material";
import useLayoutStore from "stores/layoutStore";
import { fontSize, mainColor } from "theme/default";
import { Text } from "components/Text";

const ModalGlobal = () => {
	const { openModalGlobal, setOpenModalGlobal } = useLayoutStore();
	return (
		<Modal
			keepMounted
			open={openModalGlobal}
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={setOpenModalGlobal}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Box
				display={"flex"}
				justifyContent={"center"}
				alignItems={"end"}
				border={"1px solid #fff"}
				borderRadius={"10px"}
				flexDirection={"column"}
				width={"360px"}
				sx={{ outline: "none" }}
				bgcolor={mainColor.primary}>
				<Text
					sx={{
						borderBottom: "1px solid #ccc",
						width: "100%",
						height: "50px",
						padding: "0 10px",
						lineHeight: "50px",
						fontSize: fontSize.s14,
						fontWeight: "700",
						color: "black",
					}}
					id="keep-mounted-modal-title">
					You are opening Digital Cure on another tab
				</Text>
				<Text
					id="keep-mounted-modal-description"
					sx={{
						width: "100%",
						height: "50px",
						padding: "0 10px",
						lineHeight: "50px",
						fontSize: fontSize.s14,
						fontWeight: "700px",
						color: "black",
					}}>
					Click Activate to use on this tab
				</Text>
				<Button
					onClick={() => window.location.reload()}
					sx={{ padding: "10px 20px", margin: "10px", fontWeight: "700" }}>
					Activate
				</Button>
			</Box>
		</Modal>
	);
};

export default ModalGlobal;
