import React from "react";
import { MenuItem, FormControl, Box } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Icons } from "assets";
import { Text } from "components/Text";

interface CommonSelectProps {
	options: { value: string; name: string; icon: React.ReactNode }[];
	label: string;
	value: string;
	name: string;
	onChange: (event: SelectChangeEvent) => void;
}

function CommonSelect({
	options,
	label,
	value,
	name,
	onChange,
	...selectProps
}: CommonSelectProps) {
	return (
		<FormControl>
			<Select
				sx={{
					display: "flex",
					justifyContent: "space-between",
					minWidth: "150px",
					alignItems: "center",
					borderRadius: "4px",
					border: "1px solid #ccc",
					"& .MuiSelect-select": {
						padding: "10px",
					},
					"& .MuiSelect-icon": {
						right: "12px",
						top: "50%",
						transform: "translateY(-50%)",
						color: "white",
					},
				}}
				displayEmpty
				name={name}
				labelId={`${label}-select-label`}
				id={`${label}-select`}
				value={value}
				onChange={onChange}
				IconComponent={() => (
					<Box
						sx={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
						<Icons.ArrowDown />
					</Box>
				)}
				{...selectProps}>
				{options.map((option, index) => (
					<MenuItem key={option.value} value={option.value}>
						{option.icon && (
							<Box
								sx={{
									marginRight: "8px",
									display: "flex",
									alignItems: "center",
								}}>
								{option.icon || options?.[index]?.icon}
								<Text sx={{ ml: 1 }}>{option.name}</Text>
							</Box>
						)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default CommonSelect;
