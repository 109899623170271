/** @format */
import React, { forwardRef, memo, useMemo } from "react";
import { Button as ButtonMui, CircularProgress } from "@mui/material";
import { Icons } from "assets";
import { Text } from "components/Text";
import { useKey } from "hooks";
import theme from "theme/theme";
import { IButton, ISXTheme, IThemeColor } from "types";
import { KEY_CONTEXT } from "utils";
import createStyle from "./styles";
import { fontSize, mainColor } from "theme/default";
import styled from "@emotion/styled";

const Button = forwardRef<HTMLButtonElement, IButton>(function Button(
	{
		title,
		useI18n,
		variantTitle = "body1",
		LabelProps,
		variant = "outlined",
		variantStyle = "default",
		iconProps: icon,
		children,
		sx,
		disabled = false,
		startIcon,
		isLoading = false,
		isActive = true,
		...props
	},
	ref,
) {
	const GradientButton = styled(ButtonMui)({
		background: isActive
			? "linear-gradient(to bottom, #7340D8 30%, #48D8E4 90%)"
			: "#4e4c4c",
		borderRadius: 50,
		border: 0,
		color: mainColor.primary,
		height: 56,
		padding: "0 30px",
		boxShadow: "0 3px 5px 2px rgba(105, 135, 255, .3)",
		fontSize: "16px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"&:hover": {
			color: mainColor.primary,
			opacity: 0.8,
			background: isActive
				? "linear-gradient(to bottom, #7340D8 30%, #48D8E4 90%)"
				: "#4e4c4c",
		},
	});

	// styles
	const { getKey } = useKey();
	const mode = getKey(KEY_CONTEXT.THEME_MODE) as IThemeColor;
	const styles = createStyle();
	const btnStyle = useMemo(
		() => styles[variant][variantStyle] as ISXTheme,
		[variant, variantStyle],
	);
	const stylesOverride = useMemo(() => {
		const baseStyles = [styles.base, btnStyle];
		if (Array.isArray(sx)) {
			return [...baseStyles, ...sx];
		}
		return [...baseStyles, sx];
	}, [sx, btnStyle]) as ISXTheme;

	const iconColor = useMemo(
		() =>
			icon?.color || variant !== "contained"
				? disabled
					? "rgba(0, 0, 0, 0.26)"
					: theme.palette.primary[mode]
				: "white",
		[icon, variant],
	);

	// ICON default
	const IconButton = useMemo(
		() => ({
			close: (
				<Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />
			),
			cancel: (
				<Icons.Close color={iconColor} size={icon?.size || 12} {...icon} />
			),
		}),
		[icon],
	);

	return (
		<GradientButton
			ref={ref}
			sx={stylesOverride}
			startIcon={startIcon || (icon?.variant && IconButton?.[icon?.variant])}
			disabled={disabled}
			{...props}>
			{title ? (
				<Text
					color={mainColor.primary}
					useI18n={useI18n}
					fontSize={fontSize.s16}
					fontWeight={"600"}
					variant={variantTitle}
					{...LabelProps}>
					{title}
				</Text>
			) : (
				children
			)}
			{isLoading && (
				<CircularProgress size={"20px"} sx={{ ml: "10px", color: "white" }} />
			)}
		</GradientButton>
	);
});

export default memo(Button);
