import React, { useState } from "react";
// import { IMAGES } from "assets";
import { EnumPlayGameType, IPlayGame } from "types";
import TypingEffect from "components/Messages/TypingEffect";
import { Avatar, Box } from "@mui/material";
import { imgDefault } from "assets/images";
import { mainColor } from "theme/default";
import { useCommon } from "hooks";
import { KEY_CONTEXT } from "utils";

export const GameItem: React.FC<IPlayGame | any> = ({
  question,
  answer,
  type,
  stopStream = false,
  onTypingComplete,
}) => {
  const [stop, setStop] = useState(stopStream);
  const { getItem } = useCommon();

  const avtVideo = getItem(KEY_CONTEXT.IMGS);
  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={"10px"}>
      <Box
        display={"flex"}
        flexDirection={"row"}
        gap={"10px"}
        paddingLeft={"6px"}
      >
        <Avatar
          sx={{ width: "30px", height: "30px" }}
          src={avtVideo?.replace(".mp4", ".png") ?? imgDefault}
          alt="avt"
        />

        {type === EnumPlayGameType.QUESTION ? (
          <Box
            width={"fit-content"}
            borderRadius={"0 24px 24px 24px"}
            bgcolor={mainColor.grey}
            padding={"10px"}
          >
            <TypingEffect
              speed={60}
              stopStream={stop}
              text={question?.question}
              onTypingComplete={onTypingComplete}
            />
          </Box>
        ) : (
          <Box
            width={"fit-content"}
            borderRadius={"0 24px 24px 24px"}
            bgcolor={mainColor.grey}
            padding={"10px"}
          >
            <TypingEffect
              speed={60}
              text={answer?.title}
              onTypingComplete={onTypingComplete}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
