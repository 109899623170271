import { IValueType } from "types";

export const areEqual = (prev: unknown, next: unknown) => {
  try {
    return JSON.stringify(prev) === JSON.stringify(next);
  } catch (error) {
    return false;
  }
};


export const getDataType = (value: unknown): IValueType =>
  Object.prototype.toString.call(value).slice(8, -1) as IValueType;


export const getPathByIndex = (curIdx: number, fullPath: string) => {
  return fullPath
    .split('/')
    .filter((_, idx) => idx <= curIdx)
    .join('/');
};

export const splitPath = (path: string) => {
  const parsePath = path?.[0] === '/' ? path.substring(1) : path;
  return parsePath.split('/').filter(i => i);
};

export const formatMoney = (amount:number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}