/**
 * @description define an object contains all the sources
 */
import en from './en';
import ko from './ko';

export const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
};
