/** @format */

import { IThemeColor, IUser } from 'types';
import { KEY_CONTEXT } from 'utils';

export function useKey() {

  const setKey = (key: string, value: string) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const setKeyLang = (value: string) => {
    localStorage.setItem(KEY_CONTEXT.LANGUAGE, value);
  };

  const setKeyTheme = (value: string) => {
    localStorage.setItem(KEY_CONTEXT.THEME_MODE, value);
  };
  const setKeyUser = (user: IUser) => {
    localStorage.setItem(KEY_CONTEXT.USER, JSON.stringify(user));
  };

  const getKey = (key: string) => {
    try {
      const val = key === KEY_CONTEXT.THEME_MODE
      ? (localStorage.getItem(key) || 'main')
      : localStorage.getItem(key);
      if (val) {
				return JSON.parse(val);
			}
			return null;
		} catch (error) {
			return null;
		}
  };

  // custom remove value
  const removeKey = (key: string) => {
    return localStorage.removeItem(key);
  };

  const removeAllKeys = (notKey?: string) => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key && key !== notKey) {
        removeKey(key);
      }
    }
  };

  const themeMode = (getKey(KEY_CONTEXT.THEME_MODE) || 'main') as IThemeColor;
  return {
    themeMode,
    setKeyLang,
    setKeyTheme,
    setKeyUser,
    getKey,
    removeKey,
    setKey,
    removeAllKeys
  };
}
