import React from "react";
import { Box } from "@mui/material";
import { mainColor } from "theme/default";
import { HeaderChat } from "components/Header/HeaderChat";

interface ChatLayoutProps {
  children: React.ReactNode;
}

export const ChatLayout: React.FC<ChatLayoutProps> = ({ children }) => {
  return (
    <Box bgcolor={mainColor.primary} minHeight={"100vh"}>
      <HeaderChat />
      <Box
        width={"100%"}
        height={"100%"}
        bgcolor={"#EEEEEE"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          width={"100%"}
          height={"100%"}
          minHeight="calc(100vh - 76px)"
          component={"main"}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
