import React, { useMemo } from "react";
import { EnumAnswerType, EnumPlayGameType, IPlayGame } from "types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDID, usePlayGame } from "hooks";
import { Introduction } from "./Introduction";
import { GameItem } from "./GameItem";
import { ACTIVE_TAB, STEP_GAME, sleep } from "utils";
import { useCommon } from "hooks/useCommon";
import { Box, LinearProgress } from "@mui/material";
import QuestionMessage from "components/Messages/QuestionMessage";
import { ButtonCus } from "components";
import { mainColor } from "theme/default";

export const PlayGames = ({ remoteStreamRef }: any) => {
  const { initDidStream, onStopStream, cleanup } = useDID(remoteStreamRef);
  const scrollRefGame = useRef<HTMLDivElement>();
  const {
    language,
    activeTab,
    isVideoPlaying,
    onUpdateParams,
    default_url,
    selectedType,
  } = useCommon();
  const {
    updateGameMsg,
    messagesGame,
    loadingGame,
    setLoadingGame,
    onGetImagesQuestion,
    onGetListQuestion,
    onAnswer,
  } = usePlayGame();
  const isPlayGameTab = useMemo(() => activeTab === ACTIVE_TAB.PLAY_GAME, [
    activeTab,
  ]);
  const prevRef = useRef<any>({
    countRequest: 0,
    isPlaying: false,
    step: STEP_GAME.INTRO,
    trackingStep: "",
  }).current;
  const aiMessage = useRef({
    question: null,
    answer_incorrect: null,
    answer_correct: -1,
    image_urls: [],
    images: null,
    count: 0,
  }).current;

  const initStreamDID = async () => {
    // if() {
    cleanup();
    console.log("🚀 ~ initStreamDID ~ initStreamDID:");
    const rsult = await initDidStream(
      remoteStreamRef.current,
      selectedType,
      default_url
    );
    onUpdateParams({ sessionId: rsult.session_id, streamId: rsult.id });
    console.log("🚀 ~ initStreamDID ~ initStreamDID:");
    return rsult;
    // }
  };

  useEffect(() => {
    if (!isPlayGameTab) return;
    console.log(
      "🚀 ~ useEffect ~ isPlayGameTab:[1]",
      prevRef.step,
      loadingGame
    );

    if (!loadingGame) {
      prevRef.step = STEP_GAME.INTRO;
      prevRef.trackingStep = "";
      setLoadingGame(true);
      onIntroduce();
    }
  }, [isPlayGameTab]);

  useEffect(() => {
    if (!isPlayGameTab) return;
    console.log(
      "🚀 ~ useEffect ~ step ~ isPLayVideo:",
      prevRef.isPlaying !== isVideoPlaying,
      !isVideoPlaying,
      prevRef.step !== prevRef.trackingStep
    );
    if (
      prevRef.isPlaying !== isVideoPlaying &&
      !isVideoPlaying &&
      prevRef.step !== prevRef.trackingStep
    ) {
      prevRef.trackingStep = prevRef.step;
      console.log(
        "🚀 ~ useEffect ~ step:[2]",
        prevRef.step,
        prevRef.isPlaying,
        isVideoPlaying
      );
      if (`${prevRef.step}`.includes(STEP_GAME.ANSWER_INCORRECT)) {
        onShowImages();
      }
      switch (prevRef.step) {
        case STEP_GAME.INTRO:
          onIntroduce();
          break;
        case STEP_GAME.QUESTION:
          onGetQuestion();
          break;

        case STEP_GAME.SHOW_IMAGE:
          onShowImages();
          break;
        default:
          break;
      }
    }
    prevRef.isPlaying = isVideoPlaying;
  }, [prevRef, isVideoPlaying]);

  const [isLoadingInit, setIsLoadingInit] = useState(false);
  const onIntroduce = useCallback(async () => {
    setIsLoadingInit(true);
    const res = await onGetImagesQuestion(language, !messagesGame.length);
    if (res?.status === 400 && prevRef.countRequest < 3) {
      // Show modal alert expire session

      res?.error === "DID_SESSION_ERROR" &&
        onUpdateParams({ isShowModalExpire: true });

      // prevRef.countRequest += 1;
      // await initStreamDID();
      // await sleep(1000);
      // onIntroduce();
      return;
    }
    setIsLoadingInit(false);
    aiMessage.answer_correct = -1;
    if (res?.status === 200 && prevRef.step === STEP_GAME.INTRO) {
      aiMessage.images = res?.images;
      aiMessage.image_urls = res?.image_urls;
      prevRef.step = STEP_GAME.QUESTION;
      !res?.introduces && onGetQuestion();
    }
    console.log("🚀 ~ useEffect ~ step:[0]", res);
    if (res?.introduces) {
      onUpdateStoreMsg({
        type: EnumPlayGameType.INTRODUCTION,
        introduction: {
          text: res?.introduces,
        },
      });
    }
    prevRef.countRequest = 0;
    setLoadingGame(false);
  }, [language, messagesGame]);

  const onGetQuestion = async () => {
    const rs = await onGetListQuestion(aiMessage.image_urls, language);

    if (rs?.status === 400 && prevRef.countRequest < 3) {
      // Show modal alert expire session
      rs?.error === "DID_SESSION_ERROR" &&
        onUpdateParams({ isShowModalExpire: true });

      // prevRef.countRequest += 1;
      // await initStreamDID();
      // await sleep(1000);
      // onGetQuestion();
      return;
    }
    prevRef.countRequest = 0;
    prevRef.step = STEP_GAME.SHOW_IMAGE;
    aiMessage.question = rs;
    if (aiMessage.image_urls) {
      aiMessage.answer_correct = aiMessage.image_urls?.findIndex?.(
        (e) => e === rs?.image_url
      );
    }
    console.log(
      "🚀 ~ getQuestion ~ question:",
      aiMessage.image_urls,
      rs?.image_url,
      aiMessage.answer_correct
    );
    rs &&
      onUpdateStoreMsg({
        type: EnumPlayGameType.QUESTION,
        question: rs,
      });
  };

  const onShowImages = () => {
    prevRef.countRequest = 0;
    onUpdateStoreMsg({
      type: EnumPlayGameType.LISTIMAGE,
      listimages: {
        image_urls: aiMessage.image_urls ?? [],
        images: aiMessage.images ?? [],
      },
    });
  };

  const [isLoadingGame, setIsLoadingGame] = useState(false);

  const [loadingSubmit, setLoadingSubmit] = useState({
    loading: false,
    idxItem: 0,
  });

  const submitAnswers = async (answer: any, idx: number) => {
    console.log("🚀 ~ submitAnswers ~ answer:", answer, aiMessage);
    if (
      aiMessage?.question &&
      aiMessage?.image_urls &&
      prevRef.step !== STEP_GAME.ANSWERING
    ) {
      try {
        setLoadingSubmit({ loading: true, idxItem: idx });
        prevRef.step = STEP_GAME.ANSWERING;
        const res = await onAnswer(
          language,
          aiMessage.question?.["description"],
          aiMessage?.image_urls?.[answer],
          answer === aiMessage.answer_correct
        );
        setLoadingSubmit({ loading: false, idxItem: idx });
        if (res?.status === 400 && prevRef.countRequest < 3) {
          // Show modal alert expire session
          res?.error === "DID_SESSION_ERROR" &&
            onUpdateParams({ isShowModalExpire: true });

          // prevRef.countRequest += 1;
          // prevRef.step = "";
          // await initStreamDID();
          // await sleep(1000);
          // submitAnswers(answer, idx);
          return;
        }
        console.log(
          "🚀 ~ getQuestion ~ question: ~ aiMessage:",
          aiMessage,
          answer,
          answer === aiMessage.answer_correct
        );
        if (res?.status === 200) {
          if (answer === aiMessage.answer_correct) {
            aiMessage.answer_incorrect = null;
            aiMessage.question = null;
            // image_urls: null,
            // images: null,
            prevRef.step = STEP_GAME.INTRO;
            onUpdateStoreMsg({
              type: EnumPlayGameType.ANSWER,
              answer: {
                //@ts-ignore
                title: res?.response,
                type: EnumAnswerType.CORRECT,
              },
            });
          } else {
            aiMessage.answer_incorrect = res;
            prevRef.step = `${STEP_GAME.ANSWER_INCORRECT}${aiMessage.count++}`;
            onUpdateStoreMsg({
              type: EnumPlayGameType.ANSWER,
              answer: {
                //@ts-ignore
                title: aiMessage?.answer_incorrect?.response,
                type: EnumAnswerType.INCORRECT,
              },
            });
          }
        }
      } catch (error) {
        //@ts-ignore
        // toast.error(`${error?.message || "Error"}`, {
        //   position: "top-center",
        //   autoClose: 5000,
        //   pauseOnHover: true,
        //   draggable: false,
        // });
      }
    }
  };

  const renderContent = useCallback(() => {
    const renderItem = (item: IPlayGame, ind: number) => {
      console.log("🚀 ~ useEffect ~ language PlayGame ~ item:", item);
      const stopStream = ind && ind !== messagesGame.length - 1;
      switch (item.type) {
        case EnumPlayGameType.INTRODUCTION:
          return (
            <Introduction intro={`${item?.introduction?.text}`} item={item} />
          );
        case EnumPlayGameType.LISTIMAGE:
          return (
            <QuestionMessage
              loadingSubmit={loadingSubmit}
              keyIndex={ind}
              // question={item?.introduction?.text}
              questions={item?.listimages?.images}
              onAction={(i: any) => {
                console.log("item", i);
                submitAnswers(i, ind);
              }}
            />
          );
        case EnumPlayGameType.QUESTION:
        case EnumPlayGameType.ANSWER:
          //@ts-ignore
          return (
            <GameItem
              question={item.question}
              type={item?.type}
              answer={item.answer}
            />
          );
      }
    };

    return messagesGame.map((item: IPlayGame, index: number) => (
      <div key={index}>{renderItem(item, index)}</div>
    ));
  }, [JSON.stringify(messagesGame), loadingSubmit.loading]);

  useEffect(() => {
    const deplyScroll = async () => {
      await sleep(500);
      if (scrollRefGame.current) {
        scrollRefGame.current.scrollTo({
          top: scrollRefGame.current.scrollHeight + 20,
          behavior: "smooth",
        });
      }
    };
    deplyScroll();
  }, [JSON.stringify(messagesGame)]);

  // Handle update message in store
  const onUpdateStoreMsg = useCallback(
    async (input: any) => {
      // setStopStream(false);
      // await sleep(1000);

      if (input.type === EnumPlayGameType.INTRODUCTION && input.introduction) {
        input.introduction.text;
        const count =
          input.introduction.text.filter((e: string) => e !== "").length - 1;
        const messages = input.introduction.text
          .filter((e: string) => e !== "")
          .map((m: string, i: number) => ({
            ...input,
            idx: i !== 0,
            isTime: i === count,
            introduction: {
              text: m,
            },
          }));
        let ind = 0;
        await sleep(2000);

        while (ind <= count) {
          const len = `${messages[ind]}`.length;
          updateGameMsg(messages[ind]);
          await sleep(1000);
          ind++;
          await sleep(len * 100);
        }
        return;
      }

      updateGameMsg(input);
    },
    [isPlayGameTab]
  );

  return (
    <>
      <Box
        ref={scrollRefGame}
        sx={{
          overflowY: "auto",
          padding: {
            xl: "10px",
            xs: "0px 5px 50px 0px",
          },
        }}
        display={"flex"}
        paddingBottom={"100px"}
        height={"100%"}
        flexDirection={"column"}
      >
        {renderContent()}
      </Box>

      {isLoadingInit && <LinearProgress />}
      <Box
        position={"relative"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingTop={"10px"}
        borderTop={`1px solid ${mainColor.borderColor}`}
        height={"auto"}
      >
        <ButtonCus
          sx={{ width: "200px", height: "50px" }}
          isActive={activeTab === ACTIVE_TAB.PLAY_GAME}
          onClick={() => {
            onUpdateParams({ activeTab: ACTIVE_TAB.LIVE_CHAT });
          }}
        >
          <span className="ml-1 text-white">Stop Play Game!</span>
        </ButtonCus>
      </Box>
    </>
  );
};
