import { IUser } from "types";
import {create} from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface UserState {
	user: IUser | null;
	setUser: (user: IUser) => void;
	setLanguages: (lang: { value: string; name: string }) => void;
	clearUser: () => void;
	languages: {
		value: string;
		name: string;
	};
	uid: string;
	setNewPassViaUid: (u: string) => void;
	isAuthenticated: boolean;
	setIsAuthenticated : (v: boolean) => void
}


const useUserStore = create<UserState>()(
	devtools(
		persist(
			(set) => ({
				user: null,
				uid: "",
				languages: {
					value: "en",
					name: "English",
				},
				setUser: (user) => set({ user }),
				clearUser: () => set({ user: null }),
				setLanguages: (language: { value: string; name: string }) =>
					set({
						languages: {
							value: language?.value,
							name: language?.name,
						},
					}),
				setNewPassViaUid: (u) =>
					set({
						uid: u,
					}),
				isAuthenticated: true,
				setIsAuthenticated:(u) =>  set({ isAuthenticated: u })
			}),
			{
				name: "user-storage",
				storage: createJSONStorage(() => sessionStorage),
				partialize: (state) => ({
					user: state.user,
					languages: state.languages,
				}),
			},
		),
		{
			name: "UserStore",
		},
	),
);

export default useUserStore;
