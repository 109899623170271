import React from "react";
import { Flags } from "assets";
import { EPricingPlan } from "types";

export const LANGUAGES = [
	{ value: "en", name: "English", icon: <Flags.England /> },
	{ value: "vi", name: "Tiếng Việt", icon: <Flags.VietNam /> },
];

export const MENUS = [
	{
		title: "Outfit",
		link: "/outfit",
	},
	{
		title: "FaceSwap",
		link: "/face-swap",
	},
	{
		title: "HairSwap",
		link: "/hair-swap",
	},
];

export const CATEGORY = [
	{
		value: "upper_body",
		name: "Upper body",
	},
	{
		value: "lower_body",
		name: "Lower body",
	},
	{
		value: "dresses",
		name: "Dress body",
	},
];

export const SIZE_BODY = [
	{
		value: "full-length",
		name: "Full length",
	},
	{
		value: "knee-length",
		name: "Knee length",
	},
	{
		value: "mid-thigh",
		name: "Mid thigh",
	},
];

export const DATA_BUTTON = [
	{ value: 1, name: EPricingPlan.MONTHLY },
	{ value: 12, name: EPricingPlan.ANNUAL },
];
