import { END_POINT } from "utils";
import axiosInstance from "./axios";
import { UpdateProfileForm } from "types";

export const userService = {
	getProfile: async () => {
		return await axiosInstance.get(END_POINT.USER.GET_PROFILE);
	},
	updateProfile: async (params: UpdateProfileForm) => {
		const config = {
			headers: {
			  "content-type": "multipart/form-data",
			},
		  };
	  
		  return await axiosInstance.post(END_POINT.USER.UPDATE_PROFILE, params, config);
	},
	getOtpViaMail: async (email: string, type: string) => {
		return await axiosInstance.post(END_POINT.OTP.SEND_OTP, {
			email,
			type
		});
	},
	logout: async () => {
		return await axiosInstance.post(END_POINT.USER.LOGOUT);
	},
};
