// import useWhisper from "@chengsokdara/use-whisper";
/* eslint-disable  @typescript-eslint/no-explicit-any */

import moment from "moment";
import OpenAI from "openai";
import { useCallback, useEffect, useRef, useState } from "react";

export function useOpenAI() {
  const openai = useRef({ref: new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  })}).current;
  const prevRef = useRef<{ time: number, timeout: any, timeoutRecognite: any }>({ time: 0, timeout: null, timeoutRecognite: null }).current
  const recognitionRef = useRef<any>(null);
  const streamRef = useRef<any>(null);
  // const {
  //   recording,
  //   speaking,
  //   transcribing,
  //   // transcript = {},
  //   pauseRecording,
  //   startRecording,
  //   stopRecording,
  // } = useWhisper({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY, // YOUR_OPEN_AI_TOKEN
  // });

  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[] | any>([]);
  const [transcript, setTranscript] = useState<string>()
  const [isStarted, setIsStarted] = useState<boolean>(false)

  const transcribeAudio = async (audioBlob: Blob) => {
    try {
      // Convert Blob to File
      const audioFile = new File([audioBlob], "recording.wav", {
        type: "audio/wav",
      });
      const response = await openai.ref.audio.transcriptions.create({
        model: "whisper-1",
        file: audioFile,
        response_format: 'json'
      });
      const transcription = response.text;
      console.log("Speech_recognition useOpenAI Transcription: ", transcription, moment().unix() - prevRef.time);
      setTranscript(transcription)
      // return transcription;
    } catch (error) {
      console.error("useOpenAI Error transcribing audio:", error);
      return null;
    }
  }

  useEffect(() => {
    if (isStarted && !streamRef.current) {
      const initStream = async () => {
        prevRef.time = moment().unix();
        console.log("🚀 ~useOpenAI onStartRecognite ~ prevRef.time:", prevRef.time)
        streamRef.current = await navigator.mediaDevices
        .getUserMedia({ audio: true }).catch((error) => {
          console.error("useOpenAI Error accessing microphone:", error);
        });

        // console.log("🚀 ~ useOpenAI .then ~ stream:", stream)
        mediaRecorder.current = new MediaRecorder(streamRef.current);
        mediaRecorder.current.ondataavailable = (event) => {
          console.log("🚀 useOpenAI ~ .then ~ ondataavailable:", isStarted)
          isStarted && audioChunks.current?.push(event.data);
        };
        mediaRecorder.current.onstop = () => {
          console.log("🚀 useOpenAI ~ .then ~ onstop:")
          const audioBlob = new Blob(audioChunks.current, {
            type: "audio/wav",
          });
          audioChunks.current = [];
          transcribeAudio(audioBlob);
        };
        mediaRecorder.current.start(10);
        // mediaRecorder.current.stop();
      }
      initStream()
    }
  }, [isStarted, mediaRecorder.current, streamRef.current])

  const onStopRecognite = () => {
    if (mediaRecorder.current) {
      streamRef.current?.getTracks()?.forEach(function(track: any) {
        track.stop();
      });
      streamRef.current = null

      mediaRecorder.current.stop();
      recognitionRef.current?.stop?.();
      recognitionRef.current = null;
      setIsStarted(false)
      console.log("🚀 useOpenAI ~ .then ~ onStopRecognite:")
          // setRecording(false);
    }
  }

  const onStartRecognite  = useCallback(() => {
  console.log("🚀 ~ initRecognition ~ initRecognition:0", recognitionRef.current)
  if (!('webkitSpeechRecognition' in window) || recognitionRef.current) {
      if (!('webkitSpeechRecognition' in window)) console.error('Web Speech API not supported in this browser.');
      return;
    }
    console.log("🚀 ~ initRecognition ~ initRecognition:1")

    const onTranscript = () => {
      if(prevRef.timeout) clearTimeout(prevRef.timeout)
      prevRef.timeout = setTimeout(() => {
        onStopRecognite()
        // const audioBlob = new Blob(audioChunks.current, {
        //   type: "audio/wav",
        // });
        // audioChunks.current = [];
        // transcribeAudio(audioBlob);
      }, 800)
    }
    const SpeechRecognition =
    (window as any).SpeechRecognition || window.webkitSpeechRecognition;
     
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = 'ko';

    console.log("🚀 ~ initRecognition ~ initRecognition:2", recognitionRef.current)
    recognitionRef.current.onstart = () => {
      console.log('Speech_recognition started. useOpenAI', moment().unix());
      // onStartRecognite()
      setIsStarted(true)
      prevRef.timeoutRecognite = setTimeout(() => {
        recognitionRef.current?.stop?.()
      }, 6000)
    };

    recognitionRef.current.onresult = (event: any) => {
      console.log('Speech_recognition: User is speaking. event', event);
      if (event.results.length > 0) {
        prevRef.timeoutRecognite && clearTimeout(prevRef.timeoutRecognite)
        console.log('Speech_recognition: User is speaking. useOpenAI', moment().unix());
        onTranscript()
      }
    };

    recognitionRef.current.onend = () => {
      console.log('Speech_recognition ended. useOpenAI', moment().unix());
      onStopRecognite()
    };

    recognitionRef.current.onerror = (event: any) => {
      console.error('Speech_recognition error useOpenAI', event.error);
      // onStopRecognite()
    };

    recognitionRef.current?.start?.()
  }, [recognitionRef.current, audioChunks.current]);

  return {
    onStartRecognite,
    onStopRecognite,
    transcript,
    setTranscript,
  }
}
