import React from "react";
import { Box, Modal } from "@mui/material";
import { Text } from "components/Text";
import { fontSize, mainColor } from "theme/default";
import { ButtonCus } from "components/Button";
import { Icons } from "assets";
import { useCommon } from "hooks/useCommon";

interface IModalProfile {
  open?: boolean;
  onClose?: () => void;
}

const ModalExpireSession = ({ open, onClose }: IModalProfile) => {
  const { onUpdateParams, showOptions } = useCommon();
  const handleRefetch = () => {
    setTimeout(() => onUpdateParams({ showOptions: !showOptions }), 500);
  };

  return (
    <Modal
      keepMounted
      open={true}
      sx={{
        backgroundColor: "#000000087",
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={"column"}
        padding={"30px"}
        width={"440px"}
        height={"270px"}
        borderRadius={"24px"}
        bgcolor={mainColor.primary}
      >
        <Text
          color={mainColor.secondary}
          fontWeight={"700"}
          fontSize={fontSize.s18}
        >
          Session Expire
        </Text>
        <Text
          color={mainColor.black}
          fontWeight={"400"}
          fontSize={fontSize.s16}
        >
          Please click &quot;CONFIRM&quot; to re-fetch new session
        </Text>

        <ButtonCus sx={{ width: "224px" }} onClick={handleRefetch}>
          CONFIRM
        </ButtonCus>
      </Box>
    </Modal>
  );
};

export default ModalExpireSession;
