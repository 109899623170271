/**
 * @format
 * @description define English language resource
 */

export default {
  txt: {},
  lable: {
    title_login: 'Login',
  },
  loading_text: 'Loading...',
  'Chat Box': '채팅봇',
  'Play Game':  '게임 플레이',
};
