import { FC } from "react";
import { ChatLayout, MainLayout, SubLayout } from "layouts";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import ChatPage from "pages/ChatPage";

type TComponent = FC<{
	children: string | JSX.Element | JSX.Element[] | JSX.Element;
}>;

export interface RouterType {
	path: string;
	component: FC;
	layout: TComponent;
}

const publicRoutes: RouterType[] = [
	{
		path: "/login",
		component: LoginPage,
		layout: SubLayout as TComponent,
	},
];

const privateRoutes: RouterType[] = [
	{
		path: "/",
		component: ChatPage,
		layout: ChatLayout as TComponent,
	},
	{
		path: "/chat",
		component: ChatPage,
		layout: MainLayout as TComponent,
	},
];

export { publicRoutes, privateRoutes };
