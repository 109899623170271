import React from "react";
import { Header } from "components";
import { Box } from "@mui/material";
import { mainColor } from "theme/default";

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
	return (
		<Box bgcolor={mainColor.primary} minHeight={"100vh"}>
			<Header />
			<Box
				width={"100%"}
				height={"100%"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}>
				<Box
					width={"100%"}
					height={"100%"}
					minHeight="calc(100vh - 76px)"
					component={"main"}>
					{children}
				</Box>
			</Box>
		</Box>
	);
};
