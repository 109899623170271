import { useCallback, useRef, useState } from "react";
import { EnumChatType, IMessage } from "types";
import { useCommon } from "./useCommon";
import { useUserState } from "contexts/UserContext";
import {
	DEFAULT_LANG,
	ENDPOINT_ASSISTANT,
	EXPECT_LANG,
	KEY_CONTEXT,
	createNewFileTest,
	detectLanguage,
	sleep,
} from "utils";
import axiosInstance from "services/axios";
import { useKey } from "./useKey";

export function useLiveChat() {
	const { getKey } = useKey()
	const { onUpdateParams, selectedType, streamId, sessionId } = useCommon();
	const { draftMsg = null, messages = [] } = useUserState() || {};

	const [streamAI, setStreamAI] = useState<any>();

	const peerConnectionV2 = useRef<any>(null);

	const msgRef = useRef<{ messages: IMessage[] }>({ messages: [] }).current;

	const updateMsg = useCallback(async (msg: string|string[], type = EnumChatType.AI) => {
		const now = new Date();
		if (Array.isArray(msg)) {
			const count = msg.filter((e: string) => e !== "").length - 1;
			const messages = msg.filter((e: string) => e !== "")
        .map((m: string, i: number) => ({
          message: m,
					type,
          idx: i !== 0,
					createdAt: now,
          isTime: i === count,
        }));
      let ind = 0;
      await sleep(2000);

			while (ind <= count) {
        const len = `${messages[ind]}`.length;
        setMessages(messages[ind]);
        await sleep(2000);
        ind++;
        // if (ind > count) break;
        console.log("🚀 ~ onUpdateStoreMsg ~ ind:", ind, len, len * 500);
        await sleep(len * 300);
      }
			return;
		}
		setMessages({
			message: msg,
			type,
			createdAt: now,
		})
	}, []);

	const setMessages = useCallback((m: IMessage) => {
		msgRef.messages.push(m);
		onUpdateParams({ messages: msgRef.messages });
	}, [msgRef.messages])

	const onUpdateDraftMsg = useCallback((f: boolean) => {
		const draftMsg: IMessage = {
			message: "...",
			createdAt: new Date(),
			type: EnumChatType.AI,
		};
		onUpdateParams({ draftMsg: f ? draftMsg : null });
	}, []);

	const reseChat = () => {msgRef.messages = []; onUpdateParams({ messages: [] })};

	const onValidPlayGame = async (text: string) => {
		try {
			const uid = getKey(KEY_CONTEXT.UID)
			const rs = await axiosInstance.post(
				`/get_behavior_analyst?uid=${uid}`,
				{ text },
				{
					headers: {
						"Content-Type": "application/x-www-form-urlencoded",
					},
				},
			);

			return rs;
		} catch (error) {
			console.log("🚀 ~ onValidPlayGame ~ error:", error);
		}
	};

	const onChatBot = useCallback(
		async (
			is_did_requested = true,
			session_id: string,
			stream_id?: string,
			text?: string,
			background_color = "#EBEBEB",
		) => {
			const uid = getKey(KEY_CONTEXT.UID)
			const formData: any = new FormData();
			// detectLanguage("안녕하세요")
			
			if (text) {
				const lang: any = detectLanguage(text);
				const lang_id = EXPECT_LANG.includes(lang)
				console.log("🚀 ~ useLiveChat ~ lang_id:", lang_id)
				formData.append("text", lang_id ? text: '목소리가 깨끗하지 않아, 질문을 다시 반복해달라고 부탁해');
				formData.append("lang_id", lang_id ? lang: EXPECT_LANG[0]);
			}
			formData.append("is_did_requested", `${true}`);
			formData.append("session_id", session_id ?? sessionId);
			formData.append("background_color", background_color);
			formData.append("uid", uid);
			if (selectedType) {
				formData.append("type", selectedType);
			}
			console.log("🚀 ~ StreamComponent ~ formData:", JSON.stringify(formData));

			const result = await axiosInstance.post(
				`${ENDPOINT_ASSISTANT.CREATE_CLIP_STREAM}/${stream_id ?? streamId}`,
				formData,
				{ headers: { "Content-Type": "multipart/form-data" } },
			);

			return result;
		},
		[selectedType, streamId, sessionId],
	);
	const onStartStream = async (type: string, file: File | null) => {
		if (
			peerConnectionV2.current &&
			peerConnectionV2.current.connectionState === "connected"
		) {
			return;
		}

		try {
			const peer = await createPeerConnectionV2();

			if (peer) {
				peerConnectionV2.current?.addTransceiver("video", {
					direction: "recvonly",
				});
				peerConnectionV2.current?.addTransceiver("audio", {
					direction: "recvonly",
				});

				const all = new Promise((resolve) => {
					peerConnectionV2.current?.createOffer().then((rs: any) => {
						resolve(rs);
					});
				});

				return all.then(async (offer: any) => {
					offer && peerConnectionV2.current?.setLocalDescription(offer);
					console.log("🚀 ~ StreamComponent ~ returnall.then ~ offer:", offer);

					const formData = new FormData();
					formData.append("sdp", offer?.sdp);
					formData.append("type", offer?.type);
					formData.append("virtual_model_type", type);
					formData.append("file", file ?? createNewFileTest());
					console.log(
						"🚀 ~ StreamComponent ~ returnall.then ~ formData:",
						formData,
					);
					// aiUrl
					const answer: any = await axiosInstance
						.post(`/offer`, formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.catch((err) => {
							console.log("🚀 ~ StreamComponent ~ returnall.then ~ err:", err);
							return false;
						});

					peerConnectionV2.current?.setRemoteDescription(answer);
					console.log(
						"🚀 ~ StreamComponent ~ returnall.then ~ answer:",
						answer,
					);
					return answer ? true : false;
				});
			}
		} catch (e) {
			console.log("Error during streaming setup", e);
			// return false;
		}
	};

	const createPeerConnectionV2 = async () => {
		// this.remoteStreamV2 = remoteStream;
		if (!peerConnectionV2.current) {
			const config = {
				iceServers: [{ urls: ["stun:stun.acrobits.cz:3478"] }],
			};
			peerConnectionV2.current = new RTCPeerConnection(config);

			peerConnectionV2.current.addEventListener("track", onTrackAI);
			return true;
		}
		return false;
	};

	const onTrackAI = (event: RTCTrackEvent) => {
		const stream = event.streams[0];
		if (event.track.kind === "video") {
			setStreamAI(stream);
		}
	};

	const sayHi = async (session_id: string, stream_id: string) => {
		const result = await onChatBot(
			false,
			session_id,
			stream_id,
			"안녕하세요, 누구세요?",
		);
		return result;
	};

	return {
		onChatBot,
		sayHi,
		streamAI,
		onStartStream,
		updateMsg,
		onUpdateDraftMsg,
		draftMsg,
		messages,
		reseChat,
		onValidPlayGame,
	};
}
