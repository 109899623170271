import { useState, useEffect } from 'react';

export function useWindowDimensions() {

  const hasWindow = typeof window !== 'undefined';

  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const getWindowDimensions = () => {
      const width = hasWindow ? window.innerWidth : 0;
      console.log("🚀 ~ getWindowDimensions ~ width:", width)
      const height = hasWindow ? window.innerHeight : 0;
      setWindowDimensions({
        width,
        height,
      });
    }
    
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
    });

    if (hasWindow) {
      getWindowDimensions()

      window.addEventListener('resize', getWindowDimensions);
      return () => window.removeEventListener('resize', getWindowDimensions);
    }
  }, [hasWindow]);

  return windowDimensions;
}