import React from "react";

const LoadingChat = () => {
	return (
		<div className="loader_chat">
			<span />
			<span />
			<span />
		</div>
	);
};

export default LoadingChat;
