/** @format */

import { SvgIconCus } from "components/SvgIcon";
import React from "react";
import { ISVGIcon } from "types";

const Icons = {
	Close: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="12" fill="#EBE7FF" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.46967 7.46967C7.76256 7.17678 8.23744 7.17678 8.53033 7.46967L12 10.9393L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L13.0607 12L16.5303 15.4697C16.8232 15.7626 16.8232 16.2374 16.5303 16.5303C16.2374 16.8232 15.7626 16.8232 15.4697 16.5303L12 13.0607L8.53033 16.5303C8.23744 16.8232 7.76256 16.8232 7.46967 16.5303C7.17678 16.2374 7.17678 15.7626 7.46967 15.4697L10.9393 12L7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967Z"
				fill="#21065D"
			/>
		</svg>
	),
	ArrowRight: ({
		width,
		height,
		size = 24,
		wrapColor,
		color = "#9A9A9E",
		viewBox = "0 0 24 24",
		...props
	}: ISVGIcon) => (
		<SvgIconCus
			width={width}
			size={size}
			height={height}
			wrapColor={wrapColor}
			viewBox={viewBox}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
				fill={color}
				{...props}
			/>
		</SvgIconCus>
	),
	ArrowDown: () => (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_4401_21665)">
				<path
					d="M10.5867 6.19305L7.99999 8.77971L5.41332 6.19305C5.15332 5.93305 4.73332 5.93305 4.47332 6.19305C4.21332 6.45305 4.21332 6.87305 4.47332 7.13305L7.53332 10.193C7.79332 10.453 8.21332 10.453 8.47332 10.193L11.5333 7.13305C11.7933 6.87305 11.7933 6.45305 11.5333 6.19305C11.2733 5.93971 10.8467 5.93305 10.5867 6.19305Z"
					fill="black"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4401_21665">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	EyeHide: () => (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.53009 2.46967C3.23719 2.17678 2.76232 2.17678 2.46943 2.46967C2.17653 2.76256 2.17653 3.23744 2.46943 3.53033L4.58578 5.64668C3.61207 6.32254 2.87547 7.12109 2.35985 7.90076C1.75793 8.81095 1.4165 9.75949 1.4165 10.5C1.4165 11.0782 1.62448 11.7725 1.98309 12.461C2.34869 13.163 2.8965 13.9092 3.63696 14.5927C5.12687 15.968 7.39085 17.0833 10.4998 17.0833C12.2959 17.0833 13.814 16.711 15.0659 16.1268L17.4694 18.5303C17.7623 18.8232 18.2372 18.8232 18.5301 18.5303C18.823 18.2374 18.823 17.7626 18.5301 17.4697L3.53009 2.46967ZM13.9229 14.9838L12.2057 13.2666C11.7098 13.5729 11.125 13.75 10.4998 13.75C8.70483 13.75 7.24976 12.2949 7.24976 10.5C7.24976 9.87478 7.42685 9.28992 7.73312 8.79403L5.66715 6.72806C4.74179 7.3217 4.06395 8.04327 3.61101 8.72817C3.10202 9.49782 2.9165 10.1583 2.9165 10.5C2.9165 10.7551 3.02103 11.2066 3.31346 11.7681C3.5989 12.3161 4.04068 12.924 4.65438 13.4905C5.8728 14.6152 7.77549 15.5833 10.4998 15.5833C11.8353 15.5833 12.9706 15.3507 13.9229 14.9838ZM8.85086 9.91177C8.78535 10.0955 8.74976 10.2934 8.74976 10.5C8.74976 11.4665 9.53326 12.25 10.4998 12.25C10.7064 12.25 10.9043 12.2144 11.088 12.1489L8.85086 9.91177ZM10.4998 5.41665C9.64208 5.41665 8.86806 5.51261 8.17314 5.67765C7.77014 5.77337 7.36585 5.52426 7.27014 5.12126C7.17442 4.71826 7.42353 4.31397 7.82653 4.21825C8.63884 4.02532 9.52936 3.91665 10.4998 3.91665C13.6088 3.91665 15.8728 5.03191 17.3627 6.40721C18.1032 7.09072 18.651 7.83698 19.0166 8.53895C19.3752 9.22747 19.5832 9.92172 19.5832 10.5C19.5832 11.5532 18.8904 13.0785 17.6007 14.3644C17.3074 14.6569 16.8325 14.6562 16.5401 14.3629C16.2476 14.0695 16.2483 13.5947 16.5416 13.3022C17.6598 12.1873 18.0832 11.0216 18.0832 10.5C18.0832 10.2449 17.9786 9.79332 17.6862 9.23185C17.4008 8.68381 16.959 8.07591 16.3453 7.50942C15.1269 6.38472 13.2242 5.41665 10.4998 5.41665Z"
				fill="#ccc"
			/>
		</svg>
	),
	Eye: () => (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.75 12C2.75 11.6616 2.88577 11.0954 3.24331 10.409C3.59385 9.73591 4.13459 8.9926 4.88371 8.3011C6.37297 6.9264 8.69232 5.75 12 5.75C15.3077 5.75 17.627 6.9264 19.1163 8.3011C19.8654 8.9926 20.4061 9.73591 20.7567 10.409C21.1142 11.0954 21.25 11.6616 21.25 12C21.25 12.3384 21.1142 12.9046 20.7567 13.591C20.4061 14.2641 19.8654 15.0074 19.1163 15.6989C17.627 17.0736 15.3077 18.25 12 18.25C8.69232 18.25 6.37297 17.0736 4.88371 15.6989C4.13459 15.0074 3.59385 14.2641 3.24331 13.591C2.88577 12.9046 2.75 12.3384 2.75 12ZM12 4.25C8.30768 4.25 5.62703 5.5736 3.86629 7.1989C2.99041 8.0074 2.34365 8.88909 1.91294 9.71605C1.48923 10.5296 1.25 11.3384 1.25 12C1.25 12.6616 1.48923 13.4704 1.91294 14.284C2.34365 15.1109 2.99041 15.9926 3.86629 16.8011C5.62703 18.4264 8.30768 19.75 12 19.75C15.6923 19.75 18.373 18.4264 20.1337 16.8011C21.0096 15.9926 21.6564 15.1109 22.0871 14.284C22.5108 13.4704 22.75 12.6616 22.75 12C22.75 11.3384 22.5108 10.5296 22.0871 9.71605C21.6564 8.88909 21.0096 8.0074 20.1337 7.1989C18.373 5.5736 15.6923 4.25 12 4.25ZM9.7499 12C9.7499 10.7574 10.7573 9.75002 11.9999 9.75002C13.2425 9.75002 14.2499 10.7574 14.2499 12C14.2499 13.2427 13.2425 14.25 11.9999 14.25C10.7573 14.25 9.7499 13.2427 9.7499 12ZM11.9999 8.25002C9.92883 8.25002 8.2499 9.92896 8.2499 12C8.2499 14.0711 9.92883 15.75 11.9999 15.75C14.071 15.75 15.7499 14.0711 15.7499 12C15.7499 9.92896 14.071 8.25002 11.9999 8.25002Z"
				fill="#ccc"
			/>
		</svg>
	),
	GoogleIcon: () => (
		<svg
			width="25"
			height="24"
			viewBox="0 0 25 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.26 12.273C24.26 11.4221 24.1837 10.6039 24.0418 9.81848H12.74V14.4603H19.1982C18.92 15.9603 18.0745 17.2312 16.8036 18.0821V21.0931H20.6818C22.9509 19.004 24.26 15.9276 24.26 12.273Z"
				fill="#4285F4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 23.9999C15.98 23.9999 18.6964 22.9254 20.6819 21.0926L16.8037 18.0817C15.7291 18.8017 14.3546 19.2272 12.74 19.2272C9.61456 19.2272 6.96909 17.1163 6.02546 14.2799H2.01636V17.389C3.99091 21.3108 8.0491 23.9999 12.74 23.9999Z"
				fill="#34A853"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.02546 14.2801C5.78546 13.5601 5.64909 12.791 5.64909 12.0001C5.64909 11.2092 5.78546 10.4401 6.02546 9.72006V6.61096H2.01636C1.20363 8.23097 0.73999 10.0637 0.73999 12.0001C0.73999 13.9364 1.20363 15.7692 2.01636 17.3892L6.02546 14.2801Z"
				fill="#FBBC05"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.74 4.77286C14.5018 4.77286 16.0837 5.37832 17.3273 6.56741L20.7691 3.12558C18.6909 1.18922 15.9746 0.00012207 12.74 0.00012207C8.0491 0.00012207 3.99091 2.68922 2.01636 6.61104L6.02546 9.72014C6.96909 6.88377 9.61455 4.77286 12.74 4.77286Z"
				fill="#EA4335"
			/>
		</svg>
	),
	UnCheckBox: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="17" height="17" rx="5.5" fill="white" />
			<rect x="1" y="1" width="17" height="17" rx="5.5" stroke="black" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6738 5.44714C14.9791 5.72703 14.9998 6.20146 14.7199 6.5068L8.3032 13.5068C8.16497 13.6576 7.97114 13.7454 7.76662 13.7498C7.56211 13.7543 7.36465 13.675 7.22 13.5303L4.30333 10.6137C4.01044 10.3208 4.01044 9.8459 4.30333 9.55301C4.59623 9.26011 5.0711 9.26011 5.36399 9.55301L7.72677 11.9158L13.6141 5.49321C13.894 5.18787 14.3685 5.16724 14.6738 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	Checked: () => (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<rect x="0.5" y="0.5" width="18" height="18" rx="6" fill="#21065D" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.6737 5.44714C14.9791 5.72703 14.9997 6.20146 14.7198 6.5068L8.30315 13.5068C8.16492 13.6576 7.97109 13.7454 7.76658 13.7498C7.56206 13.7543 7.3646 13.675 7.21995 13.5303L4.30329 10.6137C4.01039 10.3208 4.01039 9.8459 4.30329 9.55301C4.59618 9.26011 5.07105 9.26011 5.36395 9.55301L7.72673 11.9158L13.6141 5.49321C13.894 5.18787 14.3684 5.16724 14.6737 5.44714Z"
				fill="white"
			/>
		</svg>
	),
	List: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="16"
			viewBox="0 0 19 16"
			fill="none">
			<path
				d="M2 6.5C1.17 6.5 0.5 7.17 0.5 8C0.5 8.83 1.17 9.5 2 9.5C2.83 9.5 3.5 8.83 3.5 8C3.5 7.17 2.83 6.5 2 6.5ZM2 0.5C1.17 0.5 0.5 1.17 0.5 2C0.5 2.83 1.17 3.5 2 3.5C2.83 3.5 3.5 2.83 3.5 2C3.5 1.17 2.83 0.5 2 0.5ZM2 12.5C1.17 12.5 0.5 13.18 0.5 14C0.5 14.82 1.18 15.5 2 15.5C2.82 15.5 3.5 14.82 3.5 14C3.5 13.18 2.83 12.5 2 12.5ZM6 15H18C18.55 15 19 14.55 19 14C19 13.45 18.55 13 18 13H6C5.45 13 5 13.45 5 14C5 14.55 5.45 15 6 15ZM6 9H18C18.55 9 19 8.55 19 8C19 7.45 18.55 7 18 7H6C5.45 7 5 7.45 5 8C5 8.55 5.45 9 6 9ZM5 2C5 2.55 5.45 3 6 3H18C18.55 3 19 2.55 19 2C19 1.45 18.55 1 18 1H6C5.45 1 5 1.45 5 2Z"
				fill="white"
			/>
		</svg>
	),
	Menu: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M21 18H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 14H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 10H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 6H3"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Notification: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="22"
			viewBox="0 0 21 22"
			fill="none">
			<path
				d="M7.02051 18C7.02051 18.625 7.09395 19.3497 7.74544 19.8021C8.39692 20.2546 9.19708 20.5 10.0205 20.5C10.8439 20.5 11.6441 20.2546 12.2956 19.8021C12.9471 19.3497 13.0205 18.625 13.0205 18"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.51946 8.5C3.51946 4.91015 6.42961 2 10.0195 2V0C5.32504 0 1.51946 3.80558 1.51946 8.5V11.6074C1.51946 12.295 1.29644 12.964 0.883891 13.5141L0.620824 13.8648C0.336017 14.2446 -0.140295 14.9809 0.0394786 15.8658C0.32105 17.2517 1.53111 17.721 2.44274 17.8851C3.19781 18.021 4.07554 18.0109 4.74867 18.0032C4.89215 18.0015 5.0264 18 5.14812 18H10.0195V16H5.14812C4.97806 16 4.8095 16.0016 4.64317 16.0032C3.97644 16.0095 3.34551 16.0155 2.79707 15.9168C2.11669 15.7943 2.0277 15.6006 2.00052 15.4728C2.00112 15.4643 2.00461 15.4405 2.02161 15.3965C2.0539 15.3129 2.1174 15.2027 2.22082 15.0648L2.48389 14.7141C3.15609 13.8178 3.51946 12.7277 3.51946 11.6074V8.5ZM2.00048 15.4765C2.00044 15.4765 2.00039 15.4758 2.00044 15.4743L2.00048 15.4765Z"
				fill="#3A3A3C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5203 8.5C16.5203 4.91015 13.6093 2 10.0195 2V0C14.7139 0 18.5203 3.80558 18.5203 8.5V11.6074C18.5203 12.295 18.7433 12.964 19.1558 13.5141L19.4189 13.8648C19.7037 14.2446 20.18 14.9809 20.0002 15.8658C19.7187 17.2517 18.5086 17.721 17.597 17.8851C16.8419 18.021 15.9642 18.0109 15.291 18.0032C15.1476 18.0015 15.0133 18 14.8916 18H10.0195V16H14.8916C15.0617 16 15.2302 16.0016 15.3966 16.0032C16.0633 16.0095 16.6942 16.0155 17.2426 15.9168C17.923 15.7943 18.012 15.6006 18.0392 15.4728C18.0386 15.4643 18.0351 15.4405 18.0181 15.3965C17.9858 15.3129 17.9223 15.2027 17.8189 15.0648L17.5558 14.7141C16.8836 13.8178 16.5203 12.7277 16.5203 11.6074V8.5ZM18.0392 15.4765C18.0393 15.4765 18.0393 15.4758 18.0393 15.4743L18.0392 15.4765Z"
				fill="#3A3A3C"
			/>
		</svg>
	),
	Home: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="22"
			viewBox="0 0 20 22"
			fill="none">
			<path
				d="M1 19V8.67412C1 8.04041 1.30033 7.4442 1.80954 7.067L8.80955 1.88182C9.51674 1.35797 10.4833 1.35797 11.1905 1.88182L18.1905 7.067C18.6997 7.4442 19 8.04041 19 8.67412V19C19 20.1046 18.1046 21 17 21H3C1.89543 21 1 20.1046 1 19Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M6.5 21V14C6.5 13.1716 7.17157 12.5 8 12.5H12C12.8284 12.5 13.5 13.1716 13.5 14V21"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	),
	Setting: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M13.7462 10.9472C13.7462 12.4812 12.5188 13.7499 11 13.7499C9.48124 13.7499 8.24512 12.4812 8.24512 10.9472C8.24512 9.41318 9.48124 8.24988 11 8.24988C12.5188 8.24988 13.7462 9.41318 13.7462 10.9472Z"
				stroke="#3A3A3C"
				strokeWidth="2"
			/>
			<path
				d="M13.2127 3.04115L12.5749 1.5962C12.4149 1.23381 12.0562 1 11.66 1H10.3151C9.91901 1 9.56023 1.23381 9.40028 1.5962L8.7625 3.04115C8.14903 4.43103 6.70895 5.26643 5.19788 5.10901L3.39332 4.92102C3.00234 4.88029 2.62377 5.07239 2.42579 5.41199L1.83283 6.42909C1.63099 6.77531 1.65534 7.2086 1.89471 7.53002L2.92975 8.91989C3.84991 10.1555 3.85394 11.8475 2.93967 13.0875L1.89016 14.5108C1.65335 14.832 1.63015 15.2632 1.83113 15.608L2.42295 16.6231C2.62238 16.9652 3.00486 17.1573 3.39835 17.1132L5.17891 16.9134C6.69885 16.7429 8.15349 17.5791 8.77109 18.9783L9.40028 20.4038C9.56023 20.7662 9.91901 21 10.3151 21H11.66C12.0562 21 12.4149 20.7662 12.5749 20.4038L13.2041 18.9783C13.8217 17.5791 15.2763 16.7429 16.7963 16.9134L18.5829 17.1139C18.9733 17.1577 19.3531 16.9688 19.5539 16.6312L20.1632 15.6064C20.3676 15.2627 20.3476 14.8304 20.1124 14.5071L19.0664 13.069C18.1701 11.8368 18.174 10.1661 19.0762 8.93811L20.1079 7.53379C20.3456 7.2102 20.3667 6.77581 20.1615 6.43068L19.5511 5.40395C19.3518 5.06875 18.9758 4.87998 18.5879 4.92039L16.7773 5.10901C15.2662 5.26643 13.8261 4.43103 13.2127 3.04115Z"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	),
	Help: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke="#3A3A3C"
				strokeWidth="2"
			/>
			<path
				d="M8.5 7.71806C8.63486 7.36247 8.83863 7.04373 9.09392 6.78019C9.56143 6.29757 10.2017 6 10.908 6C12.3395 6 13.5 7.22255 13.5 8.73065C13.5 10.0598 12.5986 11.1671 11.4047 11.4112C11.1342 11.4665 10.908 11.6852 10.908 11.9613V13"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M11 17.25C11.5523 17.25 12 16.8023 12 16.25C12 15.6977 11.5523 15.25 11 15.25C10.4477 15.25 10 15.6977 10 16.25C10 16.8023 10.4477 17.25 11 17.25Z"
				fill="#3A3A3C"
			/>
		</svg>
	),
	Logout: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<path
				d="M16 17L21 12L16 7"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21 12H9"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
				stroke="#3A3A3C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Plus: (color?: string) => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke={color ?? "#3A3A3C"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	PlusWhite: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none">
			<path
				d="M21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11Z"
				stroke="white"
				strokeWidth="2"
			/>
			<path
				d="M11 7V15"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 11L7 11"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Upload: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none">
			<g clipPath="url(#clip0_4376_1330)">
				<path
					d="M16 16L12 12L8 16"
					stroke="#7340D8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12 12V21"
					stroke="#7340D8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M20.3895 18.3895C21.3648 17.8578 22.1353 17.0164 22.5793 15.9981C23.0234 14.9799 23.1157 13.8427 22.8417 12.7662C22.5677 11.6896 21.943 10.735 21.0661 10.0529C20.1893 9.37088 19.1103 9.00023 17.9995 8.9995H16.7395C16.4368 7.82874 15.8726 6.74183 15.0894 5.82049C14.3062 4.89915 13.3243 4.16735 12.2176 3.6801C11.1108 3.19286 9.90802 2.96285 8.69959 3.00738C7.49116 3.0519 6.30854 3.3698 5.24065 3.93716C4.17276 4.50453 3.24738 5.3066 2.53409 6.28308C1.8208 7.25956 1.33816 8.38503 1.12245 9.57489C0.906742 10.7647 0.963577 11.988 1.28869 13.1528C1.61379 14.3175 2.19871 15.3934 2.99947 16.2995"
					stroke="#7340D8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16 16L12 12L8 16"
					stroke="#7340D8"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4376_1330">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	Back: () => (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none">
			<path
				d="M19 9L1 9M1 9L8 1.5M1 9L8 16.5"
				stroke="#7340D8"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	),
	Mic: () => (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="36" viewBox="0 0 25 36" fill="none">
		<path d="M7.13184 17.1352L7.13184 7.12076C7.13184 4.15457 9.53641 1.75 12.5026 1.75C15.4688 1.75 17.8733 4.15457 17.8733 7.12076V17.1352C17.8733 20.1013 15.4688 22.5059 12.5026 22.5059C9.53641 22.5059 7.13184 20.1013 7.13184 17.1352Z" stroke="white" strokeWidth="2"/>
		<path d="M6.55078 34.25H18.2871" stroke="white" strokeWidth="2" strokeLinecap="round"/>
		<path d="M12.502 28.1906L12.502 33.1483" stroke="white" strokeWidth="2" strokeLinecap="round"/>
		<path d="M1.8916 20.4686C3.45169 24.8209 7.61351 27.9343 12.5025 27.9343C17.3915 27.9343 21.5533 24.8209 23.1134 20.4686" stroke="white" strokeWidth="2" strokeLinecap="round"/>
		</svg>
	)
};

export default Icons;
