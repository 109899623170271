import React, { useEffect, useState } from "react";

interface TypingEffectProps {
	speed: number;
	text: string;
	stopStream?: boolean;
	onTypingComplete?: (t: boolean) => void;
}

const TypingEffect: React.FC<TypingEffectProps> = ({
	speed,
	text,
	stopStream = false,
	onTypingComplete,
}) => {
	const [displayedText, setDisplayedText] = useState("");
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (index < text?.length) {
			const timeoutId = setTimeout(() => {
				if (onTypingComplete) {
					onTypingComplete(false);
				}
				setDisplayedText((prev) => {
					return `${prev}${text[index]}`;
				});
				setIndex(index + 1);
			}, speed);
			if (stopStream) {
				clearTimeout(timeoutId);
			}
			return () => clearTimeout(timeoutId);
		} else if (onTypingComplete) {
			onTypingComplete(true);
		}
	}, [index, text, speed, onTypingComplete, stopStream]);

	useEffect(() => {
		setDisplayedText("");
		setIndex(0);
	}, [text]);

	useEffect(() => {
		setDisplayedText(`${displayedText}`.replaceAll("|", "\n\n"));
	}, [displayedText]);

	return <div>{displayedText}</div>;
};

export default TypingEffect;
