import { useEffect } from 'react'
import { useSocket } from 'contexts';
import { TypeStatusDevice } from 'types';
import { useAlert } from './useAlert';
import { userService } from 'services/userService';
// import { useKey } from './useKey';
import useOutfitStore from 'stores/outfitStore';
import useUserStore from 'stores/userStore';

const useLoginOneDevice = (idDevice:string) => {

	const { socket } = useSocket();
	const {warn} = useAlert()
	// const {removeAllKeys} = useKey()
	const {reset} = useOutfitStore()
	const {setIsAuthenticated,isAuthenticated} = useUserStore()

	const logout = async () => {
		await userService.logout();
		// removeAllKeys("imgs");
		setIsAuthenticated(false)
		reset();
	};

    useEffect(() => {
		if (socket) {
			socket.on(`eventLogin-${idDevice}`, (data) => {
				if(data?.status === TypeStatusDevice.OFFLINE && data.deviceId === idDevice && isAuthenticated){
					warn("Your account is logged in elsewhere")
					logout()
				}
			});
			return () => {
				socket.off(`eventLogin-${idDevice}`);
			};
		}
	}, [socket,isAuthenticated]);
}

export default useLoginOneDevice
