import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { mainColor } from "theme/default";
import { HeaderProps } from "types";
import { ButtonCus } from "components/Button";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { logo } from "assets/images";
import { Chat, SportsEsports } from "@mui/icons-material";
import { useCommon } from "hooks/useCommon";
import { ACTIVE_TAB } from "utils";
import { useDID, usePlayGame } from "hooks";

export const HeaderChat: React.FC<HeaderProps> = () => {
  const { onUpdateParams, activeTab, showOptions } = useCommon();

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      component={"nav"}
      height={"76px"}
      borderBottom={`1px solid ${mainColor.borderColor}`}
      bgcolor={mainColor.grey}
    >
      <Grid container sx={{ height: "100%" }} width={"100%"} paddingX={"40px"}>
        <Grid
          item
          xs={6}
          display={"flex"}
          alignItems={"center"}
          gap={"20px"}
          justifyContent={"start"}
        >
          <Box>
            <Link
              onClick={() => {
                setTimeout(
                  () => onUpdateParams({ showOptions: !showOptions }),
                  500
                );
              }}
              to={""}
            >
              <LazyLoadImage src={logo} alt="logo" effect="blur" />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          display={"flex"}
          gap={"20px"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <ButtonCus
            onClick={() => {
              onUpdateParams({ activeTab: ACTIVE_TAB.PLAY_GAME });
            }}
            isActive={activeTab === ACTIVE_TAB.PLAY_GAME}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              height: "56px",
            }}
          >
            <SportsEsports /> Play Game
          </ButtonCus>
          <ButtonCus
            onClick={() => {
              onUpdateParams({ activeTab: ACTIVE_TAB.LIVE_CHAT });
            }}
            isActive={activeTab === ACTIVE_TAB.LIVE_CHAT}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              height: "56px",
            }}
          >
            <Chat /> Live Chat
          </ButtonCus>
        </Grid>
      </Grid>
    </Box>
  );
};
