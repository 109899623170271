import { useCallback, useEffect } from 'react'
import useLayoutStore from 'stores/layoutStore';

const useDetectMultiTab = () => {
    const {setOpenModalGlobal} = useLayoutStore()
    const generateUniqueId = useCallback(() => {
        return `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;
      },[])

    useEffect(() => {
        const channel = new BroadcastChannel('tab-detection');
        const tabId = generateUniqueId();
    
        channel.postMessage({ tabId, type: 'opened' });
        channel.onmessage = (event) => {
          if (event.data.type === 'opened' && event.data.tabId !== tabId) {
            setOpenModalGlobal(true)
          }
        };
        return () => {
          channel.close();
        };
      }, []);
}

export default useDetectMultiTab
